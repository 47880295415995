.page-news{
    padding: 40px 0 40px 0;
    .news{
        &_list{
            padding: 40px 20px;
        }
        &_content {
            padding: 20px 0;
            &:last-child{
                padding-bottom: 100px;
            }
        }
        &_ttl{
            font-family: $font-mincho;
            @include font-size(26);
        }
        &_date{
            font-family: $font-mincho;
            @include font-size(16);
            margin-bottom: 20px;
        }
        &_txt{
            font-family: $font-mincho;
            @include font-size(18);
            margin-bottom:  30px;
        }
        &_link{
            text-decoration: underline 1px #000;
            @include font-size(18);
            font-family: $font-mincho;
            p{
                text-align: right;
            }
        }
    }
}
