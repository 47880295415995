.sec-mv {
	margin-bottom: 48px;
	position: relative;
	z-index: 1;
	@include flex(center, center);
	height: 62.55vw;
	max-height: calc(100vh - 133px);
	@include mq(ipd) {
		max-height: calc(100vh - 57px);
	}
	@include mq {
		max-height: calc(100vh - 121px);
		height: 109.55vw;
		margin-bottom: 49px;
	}
}
.mv {
	&-ttl {
		text-align: center;
		@include font-size(64.19);
		font-weight: bold;
		color: white;
		text-shadow: 0 8px 11px rgba(#000000, 0.8);
		@include mq(ipd) {
			@include font-size(48);
		}
		@include mq {
			@include font-size(32);
		}
	}
	&-img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		img {
			display: block;
			width: 100%;
			height: 100%;
			@include ob;
		}
	}
}

.sec-bnr {
	margin: 0 0 73px;
	@include mq {
		margin: 0 0 34px;
	}
	.inner {
		padding: 0 7px;
	}
}
.bnr {
	&-list {
		@include flex(start, stretch, wrap);
		margin: 0 -3.5px;
		@include mq {
			margin: 0 -2.5px;
		}
		&__item {
			width: 50%;
			margin: 0 0 10px;
			padding: 0 3.5px;
			@include mq {
				padding: 0 2.5px;
				margin-bottom: 5px;
			}
			&:first-child {
				width: 100%;
			}
		}
	}
}

.sec-news {
	margin: 0 0 106px;
	@include mq {
		margin: 0 0 49px;
	}
}
.news {
	&-list {
		@include font-size(18);
		max-width: 703px;
		margin: 0 auto 36px;
		@include mq(ipd) {
			@include font-size(15.5);
		}
		@include mq {
			@include font-size(13);
			margin: 0 auto 17px;
		}
		&__item {
			border-bottom: 2px solid #858484;
			margin-bottom: 20px;
			@include mq {
				border-width: 1px;
				margin-bottom: 15px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__link {
			@include flex(start, start);
			padding: 0 11px 10px;
			@include mq(sm) {
				display: block;
				padding: 0 7px 5px;
				> * {
					display: block;
				}
			}
		}
		&__date {
			width: 97px;
			@-moz-document url-prefix() {
				width: 117px;
			}
			@include ie {
				width: 117px;
			}
			@include mq {
				@include font-size(13);
			}
			@include mq(sm) {
				width: 100%;
				margin: 0 0 1px;
			}
		}
		&__ttl {
			@include mq {
				@include font-size(14);
			}
		}
	}
	&-btn {
		max-width: 842px;
		margin: 0 auto;
		@include mq {
			padding: 0 1px;
		}
	}
}

.sec-comp {
	margin: 0 0 130px;
	@include mq {
		margin: 0 0 50px;
	}
}
.comp {
	&-bnr {
		width: 100vw;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin: 0 0 21px;
		@include mq {
			margin: 0 0 20px;
		}
		img {
			display: block;
			width: 100%;
		}
	}
	&-img {
		margin-bottom: 50px;
		@include mq {
			margin-bottom: 30px;
		}
	}
}

.sec-heading4 {
	margin: 0 0 59px;
	@include mq {
		margin: 0 0 31px;
	}
	.c-list02 {
		margin: 0 0 147px;
		@include mq {
			margin: 0 -4px 54px -3px;
		}
	}
}
.w-923 {
	max-width: 923px;
	margin: 0 auto;
}

.sec-faq {
	margin: 0 0 80px;
	@include mq {
		margin: 0 0 63px;
	}
	.inner {
		max-width: 994px;
	}
}
.faq {
	&-list {
		max-width: 733px;
		margin: 0 auto 116px;
		@include mq {
			margin-bottom: 53px;
		}
		&__item {
			border-bottom: 2px solid #858484;
			@include mq {
				border-width: 1px;
			}
			&.show {
				border-bottom: none;
				.faq-list {
					&__q {
						padding-bottom: 29px;
						@include mq {
							padding-bottom: 16px;
						}
						&:after {
							transform: translateY(-50%) rotate(0);
						}
						.faq-list__ico {
							top: 23px;
							left: 14px;
							@include mq {
								top: 12px;
								left: 6px;
							}
						}
					}
				}
			}
			.c-txt03 {
				line-height: 1.2;
				@include mq {
					@include font-size(12);
					line-height: 1.5;
				}
			}
		}
		&__ico {
			@include font-size(36);
			line-height: 1;
			color: #3047a0;
			position: absolute;
			@include mq {
				@include font-size(31.885);
			}
		}
		&__q {
			position: relative;
			padding: 32px 50px 17px 50px;
			@include smooth-transition;
			cursor: pointer;
			@include mq {
				padding: 19px 27px 15px 33px;
			}
			&:after {
				position: absolute;
				content: '▲';
				@include font-size(15);
				line-height: 1.2;
				top: 52%;
				right: 24px;
				transform: translateY(-50%) rotate(180deg);
				@include smooth-transition;
				@include mq {
					right: 4px;
				}
			}
			.faq-list__ico {
				top: 20px;
				left: 11px;
				@include mq {
					top: 11px;
					left: 6px;
				}
			}
		}
		&__a {
			display: none;
			position: relative;
			background: #e5e5e5;
			padding: 29px 30px 14px 48px;
			@include mq {
				padding: 16px 33px 9px 33px;
			}
			.c-btn02 {
				@include mq(ipd-min) {
					@include font-size(16);
				}
			}
			.c-txt03 {
				margin-bottom: 13px;
				@include mq {
					margin-bottom: 1px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			.faq-list__ico {
				top: 18px;
				left: 13px;
				@include mq {
					top: 10px;
					left: 6px;
				}
			}
		}
	}
	&-box {
		max-width: 696px;
		margin: 0 auto 100px;
		@include mq {
			margin: 0 auto 35px;
		}
	}
	&-img {
		@include flex(start, start, wrap);
		max-width: 960px;
		margin: 0 auto;
		padding: 30px 0 42px;
		@include mq(sm) {
			text-align: center;
			padding: 38px 0 17px;
		}
		> * {
			position: relative;
			padding: 91px 0 0;
			@include mq(sm) {
				padding: 51px 0 0;
				img {
					display: block;
					width: 100%;
					max-height: 208px;
					@include ob;
				}
			}
			&:before {
				font-family: $font-gothic;
				@include font-size(20);
				line-height: 1.2;
				position: absolute;
				background: #525252;
				color: white;
				padding: 22px 5px 17px 15px;
				letter-spacing: 11px;
				min-width: 160px;
				box-sizing: border-box;
				text-align: center;
				@include mq {
					@include font-size(15);
					min-width: 114px;
					padding: 10px 5px 8px 17px;
					letter-spacing: 7.7px;
				}
			}
			&:first-child {
				width: 40.9%;
				margin: 0 0 0 3.3%;
				@include mq(sm) {
					width: 91%;
					margin: 0 0 17px -1px;
				}
				&:before {
					content: 'Before';
					top: 8px;
					left: 52.7%;
					transform: translateX(-50%);
					@include mq(sm) {
						left: 57.7%;
					}
				}
				&:after {
					font-family: $font-gothic;
					@include font-size(48);
					position: absolute;
					content: '➡';
					line-height: 1;
					color: #525252;
					left: 105%;
					top: 55%;
					transform: translateY(-50%);
					@include mq(sm) {
						@include font-size(29);
						left: 102.8%;
						transform: rotate(90deg);
						top: auto;
						bottom: -3px;
					}
				}
			}
			&:last-child {
				width: 46.8%;
				margin: 0 -2px 0 auto;
				@include mq(sm) {
					width: 100%;
					margin: 0;
					padding: 45px 1px 0 2px;
					img {
						max-height: 223px;
					}
				}
				&:before {
					content: 'After';
					top: 1px;
					left: 49.6%;
					transform: translateX(-50%);
				}
			}
		}
	}
}
.rank-box {
	max-width: 733px;
	margin: 0 auto 162px;
	@include mq {
		margin-bottom: 72px;
	}
	.item {
		border-bottom: 2px solid #858484;
		position: relative;
		padding: 34px 0 20px 63px;
		@include mq {
			border-width: 1px;
			padding: 24px 0 20px 31px;
		}
		.number {
			@include flex(center, center);
			@include font-size(24);
			line-height: 1;
			position: absolute;
			top: 18px;
			left: 10px;
			width: 23px;
			height: 41px;
			background: url("../img/index/ico-number.jpg") no-repeat;
			background-size: contain;
			padding: 0 0 4px 2px;
			@include mq(ipd) {
				@include font-size(21);
			}
			@include mq {
				@include font-size(18);
				left: 5px;
				width: 17px;
				height: 30px;
				padding: 0 0 4px 0;
			}
		}
		.c-txt03 {
			line-height: 1.2;
			@include mq {
				@include font-size(12);
				line-height: 1.5;
			}
		}
	}
}

.sec-work {
	margin: 0 0 104px;
	@include mq {
		margin: 0 0 72px;
	}
	.c-row {
		&--04 {
			max-width: 1160px;
			margin: 0 auto -4px;
			padding: 0 15px;
			@include mq(sm) {
				display: block;
				white-space:nowrap;
				overflow-y: hidden;
				overflow-x: auto;
				margin: 0 auto 30px;
			}
			.col3 {
				width: calc(33.33% - 11px);
				margin-right: 16px;
				padding-bottom: 17px;
				position: relative;
				margin-bottom: 39px;
				@include mq(sm) {
					display: inline-block;
					width: 255px;
					margin-right: 6px;
					padding-bottom: 11px;
					margin-bottom: 13px;
				}
				&:before {
					position: absolute;
					content: '';
					width: 100vw;
					top: 38.5%;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					background: #e6e6e6;
				}
				&:nth-child(3n+3) {
					@include mq(sm-min) {
						margin-right: 0;
					}
				}
				&.showImg {
					.img {
						&:after {
							position: absolute;
							content: '';
							top: 0;
							bottom: 0;
							left: -100%;
							width: 100%;
							background: #e6e6e6;
						}
						img {
							opacity: 0;
						}
					}
				}
				&.active {
					&:nth-child(3n+2) {
						.img {
							&:after {
								animation-delay: 0.6s;
							}
							img {
								animation-delay: 0.6s;
							}
						}
					}
					&:nth-child(3n+3) {
						.img {
							&:after {
								animation-delay: 1.2s;
							}
							img {
								animation-delay: 1.2s;
							}
						}
					}
					.img {
						&:after {
							animation: hideBg 0.6s forwards;
						}
						img {
							animation: showImg 0.3s forwards;
						}
					}
				}
				.link {
					display: block;
					position: relative;
					z-index: 1;
				}
				.img {
					margin: 0 0 3px;
					overflow: hidden;
					&:before {
						padding-bottom: calc(274/366*100%);
						@include mq {
							padding-bottom: calc(191/255*100%);
						}
					}
				}
				.time {
					@include font-size(18);
					color: #0b0b0b;
					@include mq(ipd) {
						@include font-size(15);
					}
					@include mq {
						@include font-size(12);
						margin: 0 0 1px;
					}
				}
				.ttl {
					@include font-size(18);
					line-height: 1.3;
					margin: 0 0 8px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					@include mq(ipd) {
						@include font-size(16);
					}
					@include mq {
						@include font-size(14);
					}
				}
				.des {
					@include font-size(14);
					line-height: 1.36;
					color: #252628;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					display: -webkit-box;
					height: 14px*1.36*2;
					@include mq(ipd) {
						@include font-size(12);
						height: 12px*1.36*2;
					}
					@include mq {
						@include font-size(10);
						height: 10px*1.36*2;
					}
				}
			}
		}
	}
}
@keyframes showImg {
	0% {
		opacity: 0;
	}
	95% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes hideBg {
	0% {
		left: -100%;
	}
	40% {
		left: 0;
	}
	60% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}

.sec-makr {
	margin: 0 0 54px;
	@include mq {
		margin: 0 0 39px;
	}
}
.makr {
	&-box {
		margin: 0 0 61px;
		@include mq {
			margin: 0 0 26px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&-ttl {
		@include font-size(24);
		text-align: center;
		line-height: 1;
		margin: 0 0 28px;
		@include mq(ipd) {
			@include font-size(19.5);
		}
		@include mq {
			@include font-size(15);
			padding-bottom: 12px;
			position: relative;
			margin: 0 0 24px;
			&:before {
				position: absolute;
				content: '';
				height: 2px;
				background: #3a3a3a;
				width: 142px;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
			}
		}
	}
	&-list {
		@include flex(start, stretch, wrap);
		text-align: center;
		max-width: 904px;
		margin: 0 auto;
		.item {
			width: 11.11%;
			margin-bottom: 51px;
			@include mq(ipd) {
				width: 16.66%;
			}
			@include mq {
				margin-bottom: 18px;
			}
			@include mq(sm) {
				width: 25%;
			}
			.logo {
				@include flex(center, center);
				margin: 0 0 15px;
				@include mq {
					margin: 0 0 6px;
				}
			}
			.txt {
				@include font-size(11);
			}
		}
	}
}

.sec-area {
	margin: 0 0 86px;
	@include mq {
		margin: 0 0 44px;
	}
}
.area {
	&-cnt {
		max-width: 968px;
		margin: 0 auto;
	}
	&-ttl {
		@include font-size(23);
		margin: 0 0 32px;
		@include mq(ipd) {
			@include font-size(19);
		}
		@include mq {
			@include font-size(11);
			margin: 0 0 13px;
		}
	}
	&-box {
		@include font-size(16);
		line-height: 1.875;
		margin: 0 0 42px;
		@include mq(ipd) {
			@include font-size(13);
		}
		@include mq {
			@include font-size(11);
			line-height: 1.75;
			margin: 0 0 21px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.ttl {
			@include font-size(19);
			line-height: 1.4;
			margin: 0 0 1px;
			@include mq(ipd) {
				@include font-size(16);
			}
			@include mq {
				@include font-size(11);
				margin: 0 0 3px;
			}
		}
		span {
			margin-right: 12px;
			@include mq {
				margin-right: 8px;
			}
		}
	}
}
