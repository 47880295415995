.inner {
	display: block;
	max-width: 1030px;
	padding: 0 15px;
	margin: 0 auto;
	&-1200 {
		display: block;
		max-width: 1200px;
		padding: 0 34px;
		margin: 0 auto;
	}
	&-600{
		display: block;
		max-width: 600px;
		padding: 0;
		margin: 0 auto 100px;
	}
}



.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	color: inherit;
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
}


.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}



.c{
	//ttl
	&-ttl{
		&01{
			font-family: $font-mincho;
			font-weight: bold;
			@include font-size(18);
			line-height: 1;
			@include mq(ipd) {
				@include font-size(16.5);
			}
			@include mq {
				@include font-size(15);
			}
			@include mq(sm) {
				color: #3047a0;
			}
			.en {
				@include font-size(48);
				margin: 0 4px 0 9px;
				letter-spacing: 1px;
				text-transform: uppercase;
				@include mq(ipd) {
					@include font-size(42);
				}
				@include mq {
					@include font-size(36);
				}
				@include mq(sm) {
					display: block;
					margin: 0 0 3px;
					letter-spacing: 2.5px;
				}
			}
			.jp {
				@include mq(md-min) {
					position: relative;
					top: -2px;
				}
				@include mq(sm) {
					margin: 0 0 0 -4px;
				}
			}
		}
		&02{
			@include font-size(30);
			font-weight: bold;
			line-height: 1;
			@include mq(ipd) {
				@include font-size(25.5);
			}
			@include mq {
				@include font-size(21);
				line-height: 1.43;
			}
		}
		&03{
			@include font-size(30);
			border-bottom:2px solid #000;
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
		&04{
			@include font-size(25);
			font-weight: bold;
			margin: 20px 0 10px;
		}
		&06{
			@include font-size(25);
			font-weight: bold;
			text-align: center;
			margin: 0 0 16px;
			@include mq(ipd) {
				@include font-size(23);
			}
			@include mq {
				@include font-size(21);
				line-height: 1.43;
				margin: 0 0 11px;
			}
		}
		&07{
			@include flex(center, start);
			@include font-size(20);
			font-weight: bold;
			text-align: center;
			margin: 0 0 22px;
			color: #3a3a3a;
			@include mq(ipd) {
				@include font-size(17.5);
			}
			@include mq {
				@include font-size(15);
				margin: 0 0 18px;
			}
			> * {
				padding-bottom: 11px;
				position: relative;
				@include mq {
					padding-bottom: 10px;
				}
				&:before {
					position: absolute;
					content: '';
					height: 3px;
					bottom: 0;
					left: 0;
					right: 0;
					background: #3a3a3a;
				}
			}
		}
		&05 {
			@include font-size(22);
			font-weight: bold;
			line-height: 1.19;
			text-align: center;
			@include mq(ipd) {
				@include font-size(18.5)
			}
			@include mq {
				@include font-size(15);
				line-height: 1.47;
			}
		}
	}

	//text
	&-txt {
		&01 {
			line-height: 1.5;
			@include font-size(16);
			@include mq(ipd) {
				@include font-size(14);
			}
		}
		&02 {
			line-height: 1.8;
			@include font-size(16);
			margin: 5px 0;
			@include mq(){
				@include font-size(14);
				line-height: 1.6;
			}
		}
		&03 {
			@include font-size(15);
			line-height: 1.6;
			@include mq(ipd) {
				@include font-size(14);
			}
		}
	}

	//align
	&-al {
		&__c {
			text-align: center;
		}
		&__l {
			text-align: left;
		}
		&__r {
			text-align: right;
		}
	}

	//btn
	&-btn{
		&01{
			@include font-size(15.38);
			line-height: 1;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			background: #000000;
			color: white;
			min-height: 37px;
			min-width: 205px;
			padding: 3px 5px 0;
			@include mq(ipd) {
				@include font-size(22.785);
				padding: 18px 7px 15px;
				width: 100%;
				max-width: 266px;
			}
			> * {
				@include ie {
					transform: translateY(50%);
				}
			}
		}
		&02 {
			@include font-size(18);
			display: inline-flex;
			position: relative;
			@include mq(ipd) {
				@include font-size(15);
			}
			@include mq {
				@include font-size(14);
			}
			&:before {
				position: absolute;
				content: '';
				height: 1px;
				bottom: 4px;
				left: 0;
				right: 0;
				background: #434448;
				@include mq {
					bottom: 2px;
				}
			}
			> * {
				width: 35px;
				@include mq {
					width: 24px;
				}
			}
		}
		&03 {
			@include flex(center, center);
			text-align: center;
			@include font-size(15);
			line-height: 1.6;
			border: 2px solid #434448;
			width: 100%;
			height: 50px;
			max-width: 240px;
			margin: 0 auto;
			background: white;
			@include mq(ipd) {
				@include font-size(14);
			}
			@include mq {
				border-width: 1px;
				height: 34px;
				max-width: 231px;
			}
			> * {
				position: relative;
				padding: 2px 43px 0 37px;
				@include mq {
					padding: 0px 39px 0 22px;
				}
				&:after {
					position: absolute;
					content: '';
					width: 5px;
					height: 5px;
					border-top: 2px solid #434448;
					border-right: 2px solid #434448;
					transform: translateY(-50%) rotate(45deg);
					top: 50%;
					right: 2px;
					@include mq {
						width: 4px;
						height: 4px;
						border-top: 1px solid #434448;
						border-right: 1px solid #434448;
						right: 0;
					}
				}
			}
		}
		&04{
			@include flex(center, center);
			text-align: center;
			@include font-size(15);
			line-height: 1.6;
			font-weight: bold;
			border: 1px solid #434448;
			width: 100%;
			height: 50px;
			max-width: 310px;
			margin: 0 auto;
			position: relative;
			&:after{
				content: ">";
				position: absolute;
				top: 25%;
				right: 10%;
			}
		}
		&05{
			@include flex(center, center);
			text-align: center;
			@include font-size(15);
			line-height: 1.6;
			background-color: #6a6a6a;
			font-weight: bold;
			width: 100%;
			height: 50px;
			max-width: 310px;
			margin: 0 auto;
		}
	}
}

//color
.c {
	&-red {
		color: #ff0000;
	}
}

//list comp
.c {
	&-list {
		&__item {
			@include flex(start, start, wrap);
			margin: 0 0 63px;
			@include mq {
				margin: 0 0 41px;
			}
			&.col_l {
				.c-list {
					&__img {
						.outside {
							margin-left: calc((1000px - 100vw) / 2);
							margin-right: 0;
							@media only screen and (max-width: 1030px) {
								margin-left: -15px;
							}
							@include mq(sm) {
								margin-right: -4px;
							}
						}
					}
					&__cnt {
						margin-right: 0;
						margin-left: auto;
						padding: 44px 0 0 15px;
						@include mq {
							padding: 20px 0 0 15px;
						}
						@include mq(sm) {
							padding: 11px 0 0 0;
						}
					}
				}
			}
			&.col_r {
				flex-direction: row-reverse;
				.c-list {
					&__img {
						.outside {
							margin-right: calc((1000px - 100vw) / 2);
							@media only screen and (max-width: 1030px) {
								margin-right: -15px;
							}
							@include mq(sm) {
								margin-left: -4px;
							}
						}
					}
					&__cnt {
						margin-right: auto;
						padding: 44px 15px 0 0;
						@include mq {
							padding: 20px 15px 0 0;
						}
						@include mq(sm) {
							padding: 11px 0 0 0;
						}
					}
				}
			}
		}
		&__img {
			width: 46.8%;
			@include mq(sm) {
				width: 100%;
			}
			img {
				display: block;
				width: 100%;
				@include ob;
			}
			.outside {
				img {
					@include mq(sm-min) {
						max-height: 387px;
					}
				}
			}
		}
		&__cnt {
			width: 53.2%;
			max-width: 480px;
			&.ptpc-0 {
				@include mq(sm-min) {
					padding-top: 0 !important;
				}
			}
			@include mq(sm) {
				width: 100%;
				max-width: 100%;
			}
			.c {
				&-ttl02 {
					margin-bottom: 36px;
					@include mq {
						margin-bottom: 11px;
					}
				}
				&-txt03 {
					margin-bottom: 30px;
					@include mq {
						margin-bottom: 16px;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		&--01 {
			.c-list {
				@include mq(md-min) {
					&__img {
						width: 46.9%;
					}
					&__cnt {
						width: 53.1%;
						padding: 27px 15px 0;
						.c {
							&-ttl02 {
								margin-bottom: 38px;
							}
							&-txt03 {
								margin-bottom: 56px;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
		&--02 {
			max-width: 960px;
			margin: 0 auto;
		}
	}
	&-list02 {
		@include flex(start, stretch, wrap);
		margin: 0 -6px 50px;
		@include mq {
			margin: 0 0 30px;
		}
		.item {
			width: calc(50% - 6.5px);
			position: relative;
			z-index: 1;
			margin-bottom: 12px;
			padding: 98px 21px 39px;
			@include mq {
				width: 100%;
				padding: 26px 15px 22px;
			}
			&.col_full {
				@include mq(md-min) {
					width: 100%;
					padding-top: 135px;
					padding-bottom: 82px;
					.cnt {
						.c {
							&-ttl02 {
								margin-bottom: 58px;
							}
							&-txt03 {
								max-width: 690px;
								margin-bottom: 32px;
							}
						}
					}
				}
			}
			.cnt {
				.c {
					&-ttl02 {
						text-align: center;
						margin-bottom: 36px;
						@include mq {
							margin-bottom: 5px;
						}
					}
					&-txt03 {
						@include flex(center, start);
						max-width: 408px;
						margin: 0 auto 19px;
						@include mq {
							max-width: 100%;
							margin: 0 auto 15px;
							width: 90%;
						}
					}
				}
			}
			.img {
				position: absolute;
				top: 0;
				left: 6px;
				right: 6px;
				bottom: 0;
				z-index: -1;
				@include mq {
					left: 0;
					right: 0;
				}
				&:before {
					position: absolute;
					content: '';
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: rgba(#000, 0.3);
				}
				img {
					display: block;
					width: 100%;
					height: 100%;
					@include ob;
				}
			}
		}
	}
	&-list03 {
		@include flex(start, stretch, wrap);
		margin: 0 -42px;
		@include mq {
			margin: 0 -15px;
		}
		@include mq(sm) {
			margin: 0;
		}
		&:last-child {
			margin-bottom: -70px;
			@include mq(sm) {
				margin-bottom: -45px;
			}
		}
		.item {
			width: 50%;
			padding: 0 42px;
			margin: 0 0 70px;
			@include mq {
				padding: 0 15px;
			}
			@include mq(sm) {
				width: 100%;
				padding: 0;
				margin: 0 0 45px;
				@include flex(start, start, wrap);
				> * {
					order: 1;
				}
				.movie {
					order: 0 !important;
				}
			}
			&.col_full {
				width: 100%;
				@include flex(start, start, wrap);
				.movie {
					@include mq(sm-min) {
						width: 45.5%;
						margin: 2px 0 0;
					}
				}
				.cnt {
					@include mq(sm-min) {
						width: 54.5%;
						margin-left: auto;
						padding: 0 0 0 15px;
						max-width: 454px;
						.c {
							&-ttl02 {
								margin-bottom: 18px;
							}
							&-txt03 {
								margin-bottom: 27px;
								padding: 0;
							}
						}
					}
					@include mq(sm) {
						width: 100%;
					}
				}
			}
			&:nth-child(odd) {
				margin-right: 0;
			}
			.movie {
				position: relative;
				margin: 0 0 11px;
				@include mq(sm) {
					width: 100%;
					margin: 0 0 10px;
				}
				&:before {
					content: "";
					display: block;
					padding-bottom: calc(235/419*100%);
					@include mq(sm) {
						padding-bottom: calc(356/645*100%);
					}
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.c {
				&-ttl02 {
					text-align: center;
					margin-bottom: 34px;
					@include mq {
						margin-bottom: 4px;
					}
					@include mq(sm) {
						width: 100%;
					}
				}
				&-txt03 {
					padding: 0 10px;
					margin-bottom: 21px;
					@include mq {
						margin-bottom: 18px;
					}
					@include mq(sm) {
						width: 100%;
					}
				}
			}
			.btn {
				padding: 0 4px;
				@include mq(sm) {
					width: 100%;
					padding: 0 13px;
					margin: -2px 0 0;
				}
			}
		}
	}
}

//level
.c-level {
	margin-bottom: 50px;
	@include mq {
		margin-bottom: 30px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	> .c-txt03 {
		margin-bottom: 16px;
		@include mq {
			margin-bottom: 11px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	> .c-btn03 {
		@include mq {
			margin-bottom: 56px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

//column
.c-row {
	@include flex(start, start, wrap);
	margin: 0 0 30px;
	@include mq(sm) {
		margin: 0;
		&:last-child {
			margin-bottom: -30px;
		}
	}
	.col2 {
		width: calc(50% - 6.5px);
		margin-bottom: 15px;
		@include mq(sm) {
			width: 100%;
			margin-bottom: 30px;
		}
		&:nth-child(even) {
			margin-left: auto;
		}
		.img {
			margin: 0 0 14px;
			position: relative;
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(308/494*100%);
				@include mq {
					padding-bottom: calc(225/320*100%);
				}
			}
			img {
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include ob;
			}
		}
		.c {
			&-ttl06 {
				margin: 0 0 11px;
				@include mq {
					margin: 0 0 7px;
				}
			}
			&-txt03 {
				max-width: 461px;
				margin: 0 0 31px auto;
				@include mq {
					margin: 0 0 17px auto;
				}
				@include mq(sm) {
					width: 100%;
					margin: 0 0 17px;
					max-width: 100%;
				}
			}
		}
	}
	.col3 {
		width: calc(33.33% - 13px);
		margin-right: 19px;
		margin-bottom: 15px;
		@include mq(sm) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
		}
		&:nth-child(3n+3) {
			margin-right: 0;
		}
		.img {
			margin: 0 0 10px;
			position: relative;
			@include mq {
				margin: 0 0 21px;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(240/320*100%);
				@include mq {
					padding-bottom: calc(223/320*100%);
				}
			}
			img {
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include ob;
			}
		}
		.c {
			&-ttl06 {
				margin: 0 0 10px;
				@include ie {
					@include font-size(22);
				}
				@include mq {
					margin: 0 0 8px;
				}
			}
			&-txt03 {
				max-width: 294px;
				max-height: 72px;
				margin: 0 auto 20px;
				overflow: hidden;
				@include mq {
					margin: 0 auto 17px;
				}
				@include mq(sm) {
					max-width: 100%;
					max-height: inherit;
				}
			}
		}
	}
	.col4 {
		width: calc(25% - 16px);
		margin-right: 21px;
		margin-bottom: 15px;
		@include mq {
			width: calc(25% - 5px);
			margin-right: 6px;
		}
		@include mq(sm) {
			width: calc(50% - 5px);
			margin-right: 10px;
			margin-bottom: 30px;
			&:nth-child(even) {
				margin-right: 0;
			}
		}
		&:nth-child(4n+4) {
			@include mq(sm-min) {
				margin-right: 0;
			}
		}
		.img {
			margin: 0 0 9px;
			position: relative;
			@include mq {
				margin: 0 0 10px;
			}
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(158/234*100%);
				@include mq {
					padding-bottom: calc(110/155*100%);
				}
			}
			img {
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include ob;
			}
		}
		.c {
			&-ttl05 {
				margin: 0 0 7px;
				@include mq {
					margin: 0 0 9px;
				}
			}
			&-txt03 {
				margin: 0 0 45px;
				max-height: 74px;
				overflow: hidden;
				@include mq {
					margin: 0 0 9px;
					max-height: inherit;
					padding: 0 10px;
				}
				@include mq(xs) {
					padding: 0;
				}
			}
			
		}
		.btn {
			max-width: 221px;
			@include mq {
				max-width: 100%;
				padding: 0 16px;
			}
		}
	}
}

//table
.c-tbl {
	overflow-y: hidden;
	overflow-x: auto;
	line-height: 1.7;
	margin: 0 0 11px;
	@include mq(sm) {
		margin: 0 -15px 17px 0;
		@include font-size(10);
		line-height: 1.4;
	}
	&__main {
		width: 100%;
		min-width: 696px;
		@include mq {
			min-width: 474px;
		}
		&--02 {
			.c-tbl__head {
				padding: 17px 15px 19px;
				@include mq(sm) {
					padding: 8px 4% 10px;
				}
				@media only screen and (max-width: 524px) {
					padding: 8px 2.6% 10px;
				}
			}
		}
	}
	&__head, &__cnt {
		border: 1px solid #000;
	}
	&__head {
		background: #000;
		border-left-color: #f2f2f2;
		border-right-color: #f2f2f2;
		padding: 17px 5px 19px;
		@include mq(sm) {
			padding: 8px 2.3% 10px;
		}
		@media only screen and (max-width: 521px) {
			padding: 8px 1.8% 10px;
		}
		&:first-child {
			border-left-color: #000;
		}
		&:last-child {
			border-right-color: #000;
		}
	}
	&__cnt {
		background: #f2f2f2;
		padding: 15px 5px;
		@include mq {
			padding: 8px 2px;
		}
	}
}
