body {
	font-family: $font-gothic;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	overflow: hidden;
}


//header
.header {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	background: white;
	@include mq(ipd) {
		background: #eee;
	}
	&-t {
		@include flex(start, center, swap);
		padding: 7px 21px 6px 5px;
		@include mq(ipd) {
			height: 57px;
			padding: 0 0 0 3px;
		}
	}
	
	&-logo {
		margin: 0 33px 2px 0;
		@include mq(ipd) {
			width: 113px;
			margin: 0 18px 6px 0;
		}
		@include mq(xs) {
			width: 90px;
			margin: 0 5px 0 0;
		}
	}
	
	&-txt {
		@include font-size(18);
		line-height: 1;
		margin: 4px 0 0 0;
		@include mq(ipd) {
			@include font-size(12);
			line-height: 1.2;
			margin: 0 0 2px;
		}
		@include mq(xs) {
			@include font-size(10);
		}
	}
	
	&-r {
		margin-left: auto;
		
		.t {
			margin-bottom: 4px;
			
			.info {
				@include font-size(15);
				color: #505154;
				text-decoration: underline;
				margin-left: 8px;
			}
			
			.time {
				@include font-size(14);
			}
		}
		
		.b {
			@include flex(end, center);
			
			.tel {
				@include font-size(22);
				line-height: 1.1;
				padding: 2px 0 0 35px;
				letter-spacing: 3px;
				position: relative;
				color: #000;
				&:before {
					position: absolute;
					content: '';
					background-image: url("../img/common/ico-tel.png");
					background-repeat: no-repeat;
					background-size: contain;
					width: 22px;
					height: 22px;
					left: 0;
					top: 3px;
				}
			}
			
			.c-btn01 {
				margin: 0 0 0 20px;
			}
		}
	}
	.mobile-icon {
		display: none;
		height: 100%;
		width: 59px;
		background: rgba(#000000, 0.15);
		margin-left: auto;
		position: relative;
		@include mq(ipd) {
			display: block;
		}
		span {
			background: #000;
			height: 1px;
			width: 26px;
			position: absolute;
			left: 28.814%;
			@include smooth-transition;
			&:first-child {
				top: 36%;
			}
			&:nth-of-type(2) {
				top: 52%;
			}
			&:last-child {
				bottom: 32%;
			}
		}
		&.mobile-close {
			span {
				height: 4px;
				left: 26%;
				background: rgba(#000, 0.82);
				&:first-child {
					top: 44%;
					transform: rotate(45deg);
				}
				&:nth-of-type(2) {
					top: 44%;
					opacity: 0;
				}
				&:last-child {
					bottom: 49%;
					transform: rotate(-45deg);
				}
			}
		}
	}
	&-nav {
		@include mq(ipd) {
			position: fixed;
			top: 57px;
			left: 0;
			right: 0;
			bottom: 0;
			height: calc(100vh - 57px);
			overflow-x: hidden;
			overflow-y: auto;
			opacity: 0;
			pointer-events: none;
			@include smooth-transition;
			&.on {
				opacity: 1;
				pointer-events: auto;
			}
			> * {
				background: #d9d9d9 !important;
				color: inherit !important;
				min-height: 100%;
			}
		}
	}
	
	&-inn {
		padding: 18px 15px 17px;
		//@include mq(ipd-min) {
		//	/*fix firefox*/
		//	@-moz-document url-prefix() {
		//		padding: 18px 15px;
		//	}
		//	/*fix safari block chrome */
		//	@supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
		//		padding: 18px 15px;
		//	}
		//}
		//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and(min-width:1025px) {
		//	padding: 18px 15px;
		//}
		@include mq(ipd) {
			padding: 42px 15px 15px;
			.btn {
				margin: 0 0 23px;
			}
			.tel {
				@include font-size(24);
				margin: 0 0 4px;
			}
			.time {
				@include font-size(13);
				line-height: 1.47;
			}
		}
	}
	
	&-menu {
		@include font-size(16);
		font-weight: bold;
		@include mq(ipd-min) {
			@include flex(center, stretch);
		}
		@include mq(ipd) {
			@include font-size(20);
			margin: 0 0 42px 0;
			font-weight: normal;
		}
		.item {
			a {
				padding: 0 14px;
				position: relative;
				@include mq(ipd) {
					display: block;
					border-bottom: 1px solid #858484;
					padding: 6px 2px 4px;
				}
				&:after {
					@include mq(ipd-min) {
						position: absolute;
						content: '';
						width: 2px;
						background: white;
						right: 0;
						bottom: 0;
						top: 0;
					}
				}
			}
			
			&:first-child {
				a {
					&:before {
						@include mq(ipd-min) {
							position: absolute;
							content: '';
							width: 2px;
							background: white;
							left: 0;
							bottom: 0;
							top: 0;
						}
					}
				}
			}
		}
	}
}
@keyframes show {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

//wrapper
.wrapper {
	margin-top: 132px;
	@include mq(ipd) {
		margin-top: 57px;
	}
}


//footer
.footer {
	@include mq {
		padding-bottom: 56px;
	}
	.bg-bl {
		padding: 29px 0 32px;
		@include mq {
			padding: 23px 0 6px;
		}
	}
	.inner {
		max-width: 926px;
	}
	
	&-menu {
		@include flex(start, stretch, wrap);
		@include font-size(16);
		font-weight: bold;
		line-height: 2.1875;
		margin: 0 0 105px;
		@include mq(ipd) {
			@include font-size(14);
		}
		@include mq {
			@include font-size(12);
		}
		@include mq(sm) {
			display: block;
			//line-height: 2.05;
			line-height: 1.98;
			padding: 0 15px;
			margin: 0 0 12px;
		}
		.item {
			&:last-child {
				a {
					&:after {
						display: none;
					}
				}
			}
			a {
				white-space: nowrap;
				@include mq(sm-min) {
					padding: 0 15px;
					position: relative;
					margin-right: -1px;
				}
				&:before, &:after {
					position: absolute;
					content: '';
					width: 2px;
					background: white;
					bottom: 0;
					top: 0;
					@include mq(sm) {
						display: none;
					}
				}
				
				&:before {
					left: 0;
				}
				
				&:after {
					right: -1px;
				}
			}
		}
	}
	
	&-cnt {
		max-width: 708px;
		margin: 0 auto 69px;
		@include flex(start, start, wrap);
		@include mq {
			margin: 0 0 24px
		}
		@include mq(sm) {
			padding: 0 0 0 7px;;
		}
	}
	
	&-map {
		width: 54.6%;
		@include mq(sm) {
			width: 100%;
		}
		> * {
			display: block;
			width: 100%;
			height: 240px;
			@include mq(sm) {
				height: 218px;
			}
		}
	}
	
	&-info {
		@include font-size(18);
		line-height: 1.2;
		font-weight: bold;
		width: 45.4%;
		padding: 17px 0 0 40px;
		margin: 0 auto;
		@include mq(ipd) {
			@include font-size(15);
		}
		@include mq {
			@include font-size(12);
			line-height: 2.05;
		}
		@include mq(sm) {
			width: 100%;
			text-align: center;
			padding: 27px 0 0 0;
		}
		.location {
			margin-bottom: 22px;
			@include mq(ipd) {
				margin-bottom: 10px;
			}
			@include mq(sm) {
				margin-bottom: 0;
			}
		}
		
		.add {
			margin-bottom: 14px;
			@include mq(ipd) {
				margin-bottom: 10px;
			}
			@include mq(sm) {
				margin-bottom: 0;
			}
		}
		
		.tel {
			margin-bottom: 6px;
			@include mq(ipd) {
				margin-bottom: 10px;
			}
			@include mq(sm) {
				margin-bottom: 0;
			}
		}
		
		.time {
			@include mq(ipd-min) {
				line-height: 1.95;
			}
		}
	}
	
	.copyright {
		text-align: center;
		@include font-size(20);
		font-weight: bold;
		@include mq(ipd) {
			@include font-size(15);
		}
		@include mq {
			@include font-size(10);
		}
	}
	
	&-sns {
		position: fixed;
		z-index: 99;
		bottom: 0;
		left: 0;
		right: 0;
		color: white;
		@include font-size(13);
		font-weight: bold;
		@include mq {
			@include flex(start, stretch, swap);
		}
		.item {
			width: 33.33%;
			border-right: 1px solid white;
			background: #3047a0;
			&:nth-child(3n+3) {
				border-right: none;
			}
			> * {
				height: 56px;
				@include flex(center, stretch);
				text-align: center;
				> * {
					height: 100%;
					width: 100%;
					padding: 37px 5px 0;
					position: relative;
					background-repeat: no-repeat;
					background-position: center top 8px;
				}
			}
			.tel {
				> * {
					background-image: url("../img/common/ico-tel-w.png");
					background-size: 25px 25px;
				}
			}
			.mail {
				> * {
					background-image: url("../img/common/ico-mail-w.png");
					background-size: 32px 24px;
				}
			}
			.line {
				> * {
					background-image: url("../img/common/ico-line-gr.png");
					background-size: 31px 30px;
					background-position: center top 6px;
				}
			}
		}
	}
}


.is-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	
	body {
		-webkit-overflow-scrolling: auto;
	}
}

/* ====================================================
breadcrumb
==================================================== */
.breadcrumb {
	position: relative;
	z-index: 1;
	@include font-size(15);
	line-height: 1.75;
	&+* {
		position: relative;
		z-index: 2;
	}
	.inner {
		padding: 35px 5px 35px 5px;
		@include mq {
			padding: 13px 13px 19px;
		}
	}
}