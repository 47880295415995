@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
@font-face {
  font-family: 'HGGothic';
  src: url("../fonts/HGRGE.TTC");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HGGothic';
  src: url("../fonts/HGRGM.TTC");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HGGothic';
  src: url("../fonts/HGRSGU.TTC");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HGMincho';
  src: url("../fonts/HGRME.TTC");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HGMincho';
  src: url("../fonts/HGRMB.TTC");
  font-weight: bold;
  font-style: normal;
}

/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-0 {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-1 {
    margin-top: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-1 {
    margin-top: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-2 {
    margin-top: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-2 {
    margin-top: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-3 {
    margin-top: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-3 {
    margin-top: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-4 {
    margin-top: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-4 {
    margin-top: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-5 {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-5 {
    margin-top: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-6 {
    margin-top: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-6 {
    margin-top: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-7 {
    margin-top: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-7 {
    margin-top: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-8 {
    margin-top: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-8 {
    margin-top: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-9 {
    margin-top: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-9 {
    margin-top: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-10 {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-10 {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-11 {
    margin-top: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-11 {
    margin-top: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-12 {
    margin-top: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-12 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-13 {
    margin-top: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-13 {
    margin-top: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-14 {
    margin-top: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-14 {
    margin-top: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-15 {
    margin-top: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-15 {
    margin-top: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-16 {
    margin-top: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-16 {
    margin-top: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-17 {
    margin-top: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-17 {
    margin-top: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-18 {
    margin-top: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-18 {
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-19 {
    margin-top: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-19 {
    margin-top: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-20 {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-20 {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-21 {
    margin-top: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-21 {
    margin-top: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-22 {
    margin-top: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-22 {
    margin-top: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-23 {
    margin-top: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-23 {
    margin-top: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-24 {
    margin-top: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-24 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-25 {
    margin-top: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-25 {
    margin-top: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-26 {
    margin-top: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-26 {
    margin-top: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-27 {
    margin-top: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-27 {
    margin-top: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-28 {
    margin-top: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-28 {
    margin-top: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-29 {
    margin-top: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-29 {
    margin-top: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-30 {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-30 {
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-31 {
    margin-top: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-31 {
    margin-top: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-32 {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-32 {
    margin-top: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-33 {
    margin-top: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-33 {
    margin-top: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-34 {
    margin-top: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-34 {
    margin-top: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-35 {
    margin-top: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-35 {
    margin-top: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-36 {
    margin-top: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-36 {
    margin-top: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-37 {
    margin-top: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-37 {
    margin-top: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-38 {
    margin-top: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-38 {
    margin-top: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-39 {
    margin-top: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-39 {
    margin-top: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-40 {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-40 {
    margin-top: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-41 {
    margin-top: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-41 {
    margin-top: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-42 {
    margin-top: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-42 {
    margin-top: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-43 {
    margin-top: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-43 {
    margin-top: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-44 {
    margin-top: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-44 {
    margin-top: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-45 {
    margin-top: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-45 {
    margin-top: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-46 {
    margin-top: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-46 {
    margin-top: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-47 {
    margin-top: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-47 {
    margin-top: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-48 {
    margin-top: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-48 {
    margin-top: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-49 {
    margin-top: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-49 {
    margin-top: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-50 {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-50 {
    margin-top: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-51 {
    margin-top: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-51 {
    margin-top: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-52 {
    margin-top: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-52 {
    margin-top: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-53 {
    margin-top: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-53 {
    margin-top: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-54 {
    margin-top: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-54 {
    margin-top: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-55 {
    margin-top: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-55 {
    margin-top: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-56 {
    margin-top: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-56 {
    margin-top: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-57 {
    margin-top: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-57 {
    margin-top: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-58 {
    margin-top: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-58 {
    margin-top: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-59 {
    margin-top: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-59 {
    margin-top: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-60 {
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-60 {
    margin-top: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-61 {
    margin-top: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-61 {
    margin-top: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-62 {
    margin-top: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-62 {
    margin-top: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-63 {
    margin-top: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-63 {
    margin-top: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-64 {
    margin-top: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-64 {
    margin-top: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-65 {
    margin-top: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-65 {
    margin-top: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-66 {
    margin-top: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-66 {
    margin-top: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-67 {
    margin-top: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-67 {
    margin-top: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-68 {
    margin-top: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-68 {
    margin-top: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-69 {
    margin-top: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-69 {
    margin-top: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-70 {
    margin-top: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-70 {
    margin-top: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-71 {
    margin-top: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-71 {
    margin-top: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-72 {
    margin-top: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-72 {
    margin-top: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-73 {
    margin-top: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-73 {
    margin-top: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-74 {
    margin-top: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-74 {
    margin-top: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-75 {
    margin-top: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-75 {
    margin-top: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-76 {
    margin-top: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-76 {
    margin-top: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-77 {
    margin-top: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-77 {
    margin-top: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-78 {
    margin-top: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-78 {
    margin-top: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-79 {
    margin-top: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-79 {
    margin-top: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-80 {
    margin-top: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-80 {
    margin-top: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-81 {
    margin-top: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-81 {
    margin-top: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-82 {
    margin-top: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-82 {
    margin-top: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-83 {
    margin-top: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-83 {
    margin-top: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-84 {
    margin-top: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-84 {
    margin-top: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-85 {
    margin-top: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-85 {
    margin-top: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-86 {
    margin-top: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-86 {
    margin-top: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-87 {
    margin-top: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-87 {
    margin-top: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-88 {
    margin-top: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-88 {
    margin-top: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-89 {
    margin-top: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-89 {
    margin-top: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-90 {
    margin-top: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-90 {
    margin-top: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-91 {
    margin-top: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-91 {
    margin-top: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-92 {
    margin-top: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-92 {
    margin-top: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-93 {
    margin-top: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-93 {
    margin-top: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-94 {
    margin-top: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-94 {
    margin-top: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-95 {
    margin-top: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-95 {
    margin-top: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-96 {
    margin-top: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-96 {
    margin-top: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-97 {
    margin-top: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-97 {
    margin-top: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-98 {
    margin-top: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-98 {
    margin-top: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-99 {
    margin-top: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-99 {
    margin-top: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (min-width: 835px) {
  .mtpc-100 {
    margin-top: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mtsp-100 {
    margin-top: 100px !important;
  }
}

body {
  font-family: "HGGothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  overflow: hidden;
}

.header {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  background: white;
}

@media screen and (max-width: 1024px) {
  .header {
    background: #eee;
  }
}

.header-t {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 21px 6px 5px;
}

@media screen and (max-width: 1024px) {
  .header-t {
    height: 57px;
    padding: 0 0 0 3px;
  }
}

.header-logo {
  margin: 0 33px 2px 0;
}

@media screen and (max-width: 1024px) {
  .header-logo {
    width: 113px;
    margin: 0 18px 6px 0;
  }
}

@media screen and (max-width: 349px) {
  .header-logo {
    width: 90px;
    margin: 0 5px 0 0;
  }
}

.header-txt {
  font-size: 1.8rem;
  line-height: 1;
  margin: 4px 0 0 0;
}

@media screen and (max-width: 1024px) {
  .header-txt {
    font-size: 1.2rem;
    line-height: 1.2;
    margin: 0 0 2px;
  }
}

@media screen and (max-width: 349px) {
  .header-txt {
    font-size: 1rem;
  }
}

.header-r {
  margin-left: auto;
}

.header-r .t {
  margin-bottom: 4px;
}

.header-r .t .info {
  font-size: 1.5rem;
  color: #505154;
  text-decoration: underline;
  margin-left: 8px;
}

.header-r .t .time {
  font-size: 1.4rem;
}

.header-r .b {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-r .b .tel {
  font-size: 2.2rem;
  line-height: 1.1;
  padding: 2px 0 0 35px;
  letter-spacing: 3px;
  position: relative;
  color: #000;
}

.header-r .b .tel:before {
  position: absolute;
  content: '';
  background-image: url("../img/common/ico-tel.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
  left: 0;
  top: 3px;
}

.header-r .b .c-btn01 {
  margin: 0 0 0 20px;
}

.header .mobile-icon {
  display: none;
  height: 100%;
  width: 59px;
  background: rgba(0, 0, 0, 0.15);
  margin-left: auto;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header .mobile-icon {
    display: block;
  }
}

.header .mobile-icon span {
  background: #000;
  height: 1px;
  width: 26px;
  position: absolute;
  left: 28.814%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.header .mobile-icon span:first-child {
  top: 36%;
}

.header .mobile-icon span:nth-of-type(2) {
  top: 52%;
}

.header .mobile-icon span:last-child {
  bottom: 32%;
}

.header .mobile-icon.mobile-close span {
  height: 4px;
  left: 26%;
  background: rgba(0, 0, 0, 0.82);
}

.header .mobile-icon.mobile-close span:first-child {
  top: 44%;
  transform: rotate(45deg);
}

.header .mobile-icon.mobile-close span:nth-of-type(2) {
  top: 44%;
  opacity: 0;
}

.header .mobile-icon.mobile-close span:last-child {
  bottom: 49%;
  transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {
  .header-nav {
    position: fixed;
    top: 57px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - 57px);
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .header-nav.on {
    opacity: 1;
    pointer-events: auto;
  }
  .header-nav > * {
    background: #d9d9d9 !important;
    color: inherit !important;
    min-height: 100%;
  }
}

.header-inn {
  padding: 18px 15px 17px;
}

@media screen and (max-width: 1024px) {
  .header-inn {
    padding: 42px 15px 15px;
  }
  .header-inn .btn {
    margin: 0 0 23px;
  }
  .header-inn .tel {
    font-size: 2.4rem;
    margin: 0 0 4px;
  }
  .header-inn .time {
    font-size: 1.3rem;
    line-height: 1.47;
  }
}

.header-menu {
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (min-width: 1025px) {
  .header-menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu {
    font-size: 2rem;
    margin: 0 0 42px 0;
    font-weight: normal;
  }
}

.header-menu .item a {
  padding: 0 14px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header-menu .item a {
    display: block;
    border-bottom: 1px solid #858484;
    padding: 6px 2px 4px;
  }
}

@media screen and (min-width: 1025px) {
  .header-menu .item a:after {
    position: absolute;
    content: '';
    width: 2px;
    background: white;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

@media screen and (min-width: 1025px) {
  .header-menu .item:first-child a:before {
    position: absolute;
    content: '';
    width: 2px;
    background: white;
    left: 0;
    bottom: 0;
    top: 0;
  }
}

@keyframes show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.wrapper {
  margin-top: 132px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 57px;
  }
}

@media screen and (max-width: 834px) {
  .footer {
    padding-bottom: 56px;
  }
}

.footer .bg-bl {
  padding: 29px 0 32px;
}

@media screen and (max-width: 834px) {
  .footer .bg-bl {
    padding: 23px 0 6px;
  }
}

.footer .inner {
  max-width: 926px;
}

.footer-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.1875;
  margin: 0 0 105px;
}

@media screen and (max-width: 1024px) {
  .footer-menu {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .footer-menu {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 640px) {
  .footer-menu {
    display: block;
    line-height: 1.98;
    padding: 0 15px;
    margin: 0 0 12px;
  }
}

.footer-menu .item:last-child a:after {
  display: none;
}

.footer-menu .item a {
  white-space: nowrap;
}

@media screen and (min-width: 641px) {
  .footer-menu .item a {
    padding: 0 15px;
    position: relative;
    margin-right: -1px;
  }
}

.footer-menu .item a:before, .footer-menu .item a:after {
  position: absolute;
  content: '';
  width: 2px;
  background: white;
  bottom: 0;
  top: 0;
}

@media screen and (max-width: 640px) {
  .footer-menu .item a:before, .footer-menu .item a:after {
    display: none;
  }
}

.footer-menu .item a:before {
  left: 0;
}

.footer-menu .item a:after {
  right: -1px;
}

.footer-cnt {
  max-width: 708px;
  margin: 0 auto 69px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .footer-cnt {
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 640px) {
  .footer-cnt {
    padding: 0 0 0 7px;
  }
}

.footer-map {
  width: 54.6%;
}

@media screen and (max-width: 640px) {
  .footer-map {
    width: 100%;
  }
}

.footer-map > * {
  display: block;
  width: 100%;
  height: 240px;
}

@media screen and (max-width: 640px) {
  .footer-map > * {
    height: 218px;
  }
}

.footer-info {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: bold;
  width: 45.4%;
  padding: 17px 0 0 40px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .footer-info {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 834px) {
  .footer-info {
    font-size: 1.2rem;
    line-height: 2.05;
  }
}

@media screen and (max-width: 640px) {
  .footer-info {
    width: 100%;
    text-align: center;
    padding: 27px 0 0 0;
  }
}

.footer-info .location {
  margin-bottom: 22px;
}

@media screen and (max-width: 1024px) {
  .footer-info .location {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .footer-info .location {
    margin-bottom: 0;
  }
}

.footer-info .add {
  margin-bottom: 14px;
}

@media screen and (max-width: 1024px) {
  .footer-info .add {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .footer-info .add {
    margin-bottom: 0;
  }
}

.footer-info .tel {
  margin-bottom: 6px;
}

@media screen and (max-width: 1024px) {
  .footer-info .tel {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .footer-info .tel {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1025px) {
  .footer-info .time {
    line-height: 1.95;
  }
}

.footer .copyright {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .footer .copyright {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 834px) {
  .footer .copyright {
    font-size: 1rem;
  }
}

.footer-sns {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .footer-sns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.footer-sns .item {
  width: 33.33%;
  border-right: 1px solid white;
  background: #3047a0;
}

.footer-sns .item:nth-child(3n+3) {
  border-right: none;
}

.footer-sns .item > * {
  height: 56px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
}

.footer-sns .item > * > * {
  height: 100%;
  width: 100%;
  padding: 37px 5px 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top 8px;
}

.footer-sns .item .tel > * {
  background-image: url("../img/common/ico-tel-w.png");
  background-size: 25px 25px;
}

.footer-sns .item .mail > * {
  background-image: url("../img/common/ico-mail-w.png");
  background-size: 32px 24px;
}

.footer-sns .item .line > * {
  background-image: url("../img/common/ico-line-gr.png");
  background-size: 31px 30px;
  background-position: center top 6px;
}

.is-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.is-locked body {
  -webkit-overflow-scrolling: auto;
}

/* ====================================================
breadcrumb
==================================================== */
.breadcrumb {
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  line-height: 1.75;
}

.breadcrumb + * {
  position: relative;
  z-index: 2;
}

.breadcrumb .inner {
  padding: 35px 5px 35px 5px;
}

@media screen and (max-width: 834px) {
  .breadcrumb .inner {
    padding: 13px 13px 19px;
  }
}

.inner {
  display: block;
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
}

.inner-1200 {
  display: block;
  max-width: 1200px;
  padding: 0 34px;
  margin: 0 auto;
}

.inner-600 {
  display: block;
  max-width: 600px;
  padding: 0;
  margin: 0 auto 100px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 349px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.c-ttl01 {
  font-family: "HGMincho", YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
}

@media screen and (max-width: 1024px) {
  .c-ttl01 {
    font-size: 1.65rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl01 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 640px) {
  .c-ttl01 {
    color: #3047a0;
  }
}

.c-ttl01 .en {
  font-size: 4.8rem;
  margin: 0 4px 0 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .c-ttl01 .en {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl01 .en {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 640px) {
  .c-ttl01 .en {
    display: block;
    margin: 0 0 3px;
    letter-spacing: 2.5px;
  }
}

@media screen and (min-width: 835px) {
  .c-ttl01 .jp {
    position: relative;
    top: -2px;
  }
}

@media screen and (max-width: 640px) {
  .c-ttl01 .jp {
    margin: 0 0 0 -4px;
  }
}

.c-ttl02 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
}

@media screen and (max-width: 1024px) {
  .c-ttl02 {
    font-size: 2.55rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl02 {
    font-size: 2.1rem;
    line-height: 1.43;
  }
}

.c-ttl03 {
  font-size: 3rem;
  border-bottom: 2px solid #000;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.c-ttl04 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 20px 0 10px;
}

.c-ttl06 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0 16px;
}

@media screen and (max-width: 1024px) {
  .c-ttl06 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl06 {
    font-size: 2.1rem;
    line-height: 1.43;
    margin: 0 0 11px;
  }
}

.c-ttl07 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0 22px;
  color: #3a3a3a;
}

@media screen and (max-width: 1024px) {
  .c-ttl07 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl07 {
    font-size: 1.5rem;
    margin: 0 0 18px;
  }
}

.c-ttl07 > * {
  padding-bottom: 11px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .c-ttl07 > * {
    padding-bottom: 10px;
  }
}

.c-ttl07 > *:before {
  position: absolute;
  content: '';
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #3a3a3a;
}

.c-ttl05 {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.19;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .c-ttl05 {
    font-size: 1.85rem;
  }
}

@media screen and (max-width: 834px) {
  .c-ttl05 {
    font-size: 1.5rem;
    line-height: 1.47;
  }
}

.c-txt01 {
  line-height: 1.5;
  font-size: 1.6rem;
}

@media screen and (max-width: 1024px) {
  .c-txt01 {
    font-size: 1.4rem;
  }
}

.c-txt02 {
  line-height: 1.8;
  font-size: 1.6rem;
  margin: 5px 0;
}

@media screen and (max-width: 834px) {
  .c-txt02 {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

.c-txt03 {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media screen and (max-width: 1024px) {
  .c-txt03 {
    font-size: 1.4rem;
  }
}

.c-al__c {
  text-align: center;
}

.c-al__l {
  text-align: left;
}

.c-al__r {
  text-align: right;
}

.c-btn01 {
  font-size: 1.538rem;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000000;
  color: white;
  min-height: 37px;
  min-width: 205px;
  padding: 3px 5px 0;
}

@media screen and (max-width: 1024px) {
  .c-btn01 {
    font-size: 2.2785rem;
    padding: 18px 7px 15px;
    width: 100%;
    max-width: 266px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-btn01 > * {
    transform: translateY(50%);
  }
}

.c-btn02 {
  font-size: 1.8rem;
  display: inline-flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .c-btn02 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 834px) {
  .c-btn02 {
    font-size: 1.4rem;
  }
}

.c-btn02:before {
  position: absolute;
  content: '';
  height: 1px;
  bottom: 4px;
  left: 0;
  right: 0;
  background: #434448;
}

@media screen and (max-width: 834px) {
  .c-btn02:before {
    bottom: 2px;
  }
}

.c-btn02 > * {
  width: 35px;
}

@media screen and (max-width: 834px) {
  .c-btn02 > * {
    width: 24px;
  }
}

.c-btn03 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6;
  border: 2px solid #434448;
  width: 100%;
  height: 50px;
  max-width: 240px;
  margin: 0 auto;
  background: white;
}

@media screen and (max-width: 1024px) {
  .c-btn03 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .c-btn03 {
    border-width: 1px;
    height: 34px;
    max-width: 231px;
  }
}

.c-btn03 > * {
  position: relative;
  padding: 2px 43px 0 37px;
}

@media screen and (max-width: 834px) {
  .c-btn03 > * {
    padding: 0px 39px 0 22px;
  }
}

.c-btn03 > *:after {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-top: 2px solid #434448;
  border-right: 2px solid #434448;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  right: 2px;
}

@media screen and (max-width: 834px) {
  .c-btn03 > *:after {
    width: 4px;
    height: 4px;
    border-top: 1px solid #434448;
    border-right: 1px solid #434448;
    right: 0;
  }
}

.c-btn04 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: bold;
  border: 1px solid #434448;
  width: 100%;
  height: 50px;
  max-width: 310px;
  margin: 0 auto;
  position: relative;
}

.c-btn04:after {
  content: ">";
  position: absolute;
  top: 25%;
  right: 10%;
}

.c-btn05 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6;
  background-color: #6a6a6a;
  font-weight: bold;
  width: 100%;
  height: 50px;
  max-width: 310px;
  margin: 0 auto;
}

.c-red {
  color: #ff0000;
}

.c-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 63px;
}

@media screen and (max-width: 834px) {
  .c-list__item {
    margin: 0 0 41px;
  }
}

.c-list__item.col_l .c-list__img .outside {
  margin-left: calc((1000px - 100vw) / 2);
  margin-right: 0;
}

@media only screen and (max-width: 1030px) {
  .c-list__item.col_l .c-list__img .outside {
    margin-left: -15px;
  }
}

@media screen and (max-width: 640px) {
  .c-list__item.col_l .c-list__img .outside {
    margin-right: -4px;
  }
}

.c-list__item.col_l .c-list__cnt {
  margin-right: 0;
  margin-left: auto;
  padding: 44px 0 0 15px;
}

@media screen and (max-width: 834px) {
  .c-list__item.col_l .c-list__cnt {
    padding: 20px 0 0 15px;
  }
}

@media screen and (max-width: 640px) {
  .c-list__item.col_l .c-list__cnt {
    padding: 11px 0 0 0;
  }
}

.c-list__item.col_r {
  flex-direction: row-reverse;
}

.c-list__item.col_r .c-list__img .outside {
  margin-right: calc((1000px - 100vw) / 2);
}

@media only screen and (max-width: 1030px) {
  .c-list__item.col_r .c-list__img .outside {
    margin-right: -15px;
  }
}

@media screen and (max-width: 640px) {
  .c-list__item.col_r .c-list__img .outside {
    margin-left: -4px;
  }
}

.c-list__item.col_r .c-list__cnt {
  margin-right: auto;
  padding: 44px 15px 0 0;
}

@media screen and (max-width: 834px) {
  .c-list__item.col_r .c-list__cnt {
    padding: 20px 15px 0 0;
  }
}

@media screen and (max-width: 640px) {
  .c-list__item.col_r .c-list__cnt {
    padding: 11px 0 0 0;
  }
}

.c-list__img {
  width: 46.8%;
}

@media screen and (max-width: 640px) {
  .c-list__img {
    width: 100%;
  }
}

.c-list__img img {
  display: block;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

@media screen and (min-width: 641px) {
  .c-list__img .outside img {
    max-height: 387px;
  }
}

.c-list__cnt {
  width: 53.2%;
  max-width: 480px;
}

@media screen and (min-width: 641px) {
  .c-list__cnt.ptpc-0 {
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 640px) {
  .c-list__cnt {
    width: 100%;
    max-width: 100%;
  }
}

.c-list__cnt .c-ttl02 {
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .c-list__cnt .c-ttl02 {
    margin-bottom: 11px;
  }
}

.c-list__cnt .c-txt03 {
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .c-list__cnt .c-txt03 {
    margin-bottom: 16px;
  }
}

.c-list__cnt .c-txt03:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 835px) {
  .c-list--01 .c-list__img {
    width: 46.9%;
  }
  .c-list--01 .c-list__cnt {
    width: 53.1%;
    padding: 27px 15px 0;
  }
  .c-list--01 .c-list__cnt .c-ttl02 {
    margin-bottom: 38px;
  }
  .c-list--01 .c-list__cnt .c-txt03 {
    margin-bottom: 56px;
  }
  .c-list--01 .c-list__cnt .c-txt03:last-child {
    margin-bottom: 0;
  }
}

.c-list--02 {
  max-width: 960px;
  margin: 0 auto;
}

.c-list02 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -6px 50px;
}

@media screen and (max-width: 834px) {
  .c-list02 {
    margin: 0 0 30px;
  }
}

.c-list02 .item {
  width: calc(50% - 6.5px);
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
  padding: 98px 21px 39px;
}

@media screen and (max-width: 834px) {
  .c-list02 .item {
    width: 100%;
    padding: 26px 15px 22px;
  }
}

@media screen and (min-width: 835px) {
  .c-list02 .item.col_full {
    width: 100%;
    padding-top: 135px;
    padding-bottom: 82px;
  }
  .c-list02 .item.col_full .cnt .c-ttl02 {
    margin-bottom: 58px;
  }
  .c-list02 .item.col_full .cnt .c-txt03 {
    max-width: 690px;
    margin-bottom: 32px;
  }
}

.c-list02 .item .cnt .c-ttl02 {
  text-align: center;
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .c-list02 .item .cnt .c-ttl02 {
    margin-bottom: 5px;
  }
}

.c-list02 .item .cnt .c-txt03 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 408px;
  margin: 0 auto 19px;
}

@media screen and (max-width: 834px) {
  .c-list02 .item .cnt .c-txt03 {
    max-width: 100%;
    margin: 0 auto 15px;
    width: 90%;
  }
}

.c-list02 .item .img {
  position: absolute;
  top: 0;
  left: 6px;
  right: 6px;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .c-list02 .item .img {
    left: 0;
    right: 0;
  }
}

.c-list02 .item .img:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}

.c-list02 .item .img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.c-list03 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -42px;
}

@media screen and (max-width: 834px) {
  .c-list03 {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 640px) {
  .c-list03 {
    margin: 0;
  }
}

.c-list03:last-child {
  margin-bottom: -70px;
}

@media screen and (max-width: 640px) {
  .c-list03:last-child {
    margin-bottom: -45px;
  }
}

.c-list03 .item {
  width: 50%;
  padding: 0 42px;
  margin: 0 0 70px;
}

@media screen and (max-width: 834px) {
  .c-list03 .item {
    padding: 0 15px;
  }
}

@media screen and (max-width: 640px) {
  .c-list03 .item {
    width: 100%;
    padding: 0;
    margin: 0 0 45px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-list03 .item > * {
    order: 1;
  }
  .c-list03 .item .movie {
    order: 0 !important;
  }
}

.c-list03 .item.col_full {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (min-width: 641px) {
  .c-list03 .item.col_full .movie {
    width: 45.5%;
    margin: 2px 0 0;
  }
}

@media screen and (min-width: 641px) {
  .c-list03 .item.col_full .cnt {
    width: 54.5%;
    margin-left: auto;
    padding: 0 0 0 15px;
    max-width: 454px;
  }
  .c-list03 .item.col_full .cnt .c-ttl02 {
    margin-bottom: 18px;
  }
  .c-list03 .item.col_full .cnt .c-txt03 {
    margin-bottom: 27px;
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .c-list03 .item.col_full .cnt {
    width: 100%;
  }
}

.c-list03 .item:nth-child(odd) {
  margin-right: 0;
}

.c-list03 .item .movie {
  position: relative;
  margin: 0 0 11px;
}

@media screen and (max-width: 640px) {
  .c-list03 .item .movie {
    width: 100%;
    margin: 0 0 10px;
  }
}

.c-list03 .item .movie:before {
  content: "";
  display: block;
  padding-bottom: calc(235/419*100%);
}

@media screen and (max-width: 640px) {
  .c-list03 .item .movie:before {
    padding-bottom: calc(356/645*100%);
  }
}

.c-list03 .item .movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.c-list03 .item .c-ttl02 {
  text-align: center;
  margin-bottom: 34px;
}

@media screen and (max-width: 834px) {
  .c-list03 .item .c-ttl02 {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 640px) {
  .c-list03 .item .c-ttl02 {
    width: 100%;
  }
}

.c-list03 .item .c-txt03 {
  padding: 0 10px;
  margin-bottom: 21px;
}

@media screen and (max-width: 834px) {
  .c-list03 .item .c-txt03 {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 640px) {
  .c-list03 .item .c-txt03 {
    width: 100%;
  }
}

.c-list03 .item .btn {
  padding: 0 4px;
}

@media screen and (max-width: 640px) {
  .c-list03 .item .btn {
    width: 100%;
    padding: 0 13px;
    margin: -2px 0 0;
  }
}

.c-level {
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .c-level {
    margin-bottom: 30px;
  }
}

.c-level:last-child {
  margin-bottom: 0;
}

.c-level > .c-txt03 {
  margin-bottom: 16px;
}

@media screen and (max-width: 834px) {
  .c-level > .c-txt03 {
    margin-bottom: 11px;
  }
}

.c-level > .c-txt03:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 834px) {
  .c-level > .c-btn03 {
    margin-bottom: 56px;
  }
  .c-level > .c-btn03:last-child {
    margin-bottom: 0;
  }
}

.c-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 30px;
}

@media screen and (max-width: 640px) {
  .c-row {
    margin: 0;
  }
  .c-row:last-child {
    margin-bottom: -30px;
  }
}

.c-row .col2 {
  width: calc(50% - 6.5px);
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .c-row .col2 {
    width: 100%;
    margin-bottom: 30px;
  }
}

.c-row .col2:nth-child(even) {
  margin-left: auto;
}

.c-row .col2 .img {
  margin: 0 0 14px;
  position: relative;
}

.c-row .col2 .img:before {
  content: "";
  display: block;
  padding-bottom: calc(308/494*100%);
}

@media screen and (max-width: 834px) {
  .c-row .col2 .img:before {
    padding-bottom: calc(225/320*100%);
  }
}

.c-row .col2 .img img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.c-row .col2 .c-ttl06 {
  margin: 0 0 11px;
}

@media screen and (max-width: 834px) {
  .c-row .col2 .c-ttl06 {
    margin: 0 0 7px;
  }
}

.c-row .col2 .c-txt03 {
  max-width: 461px;
  margin: 0 0 31px auto;
}

@media screen and (max-width: 834px) {
  .c-row .col2 .c-txt03 {
    margin: 0 0 17px auto;
  }
}

@media screen and (max-width: 640px) {
  .c-row .col2 .c-txt03 {
    width: 100%;
    margin: 0 0 17px;
    max-width: 100%;
  }
}

.c-row .col3 {
  width: calc(33.33% - 13px);
  margin-right: 19px;
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .c-row .col3 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.c-row .col3:nth-child(3n+3) {
  margin-right: 0;
}

.c-row .col3 .img {
  margin: 0 0 10px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .c-row .col3 .img {
    margin: 0 0 21px;
  }
}

.c-row .col3 .img:before {
  content: "";
  display: block;
  padding-bottom: calc(240/320*100%);
}

@media screen and (max-width: 834px) {
  .c-row .col3 .img:before {
    padding-bottom: calc(223/320*100%);
  }
}

.c-row .col3 .img img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.c-row .col3 .c-ttl06 {
  margin: 0 0 10px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-row .col3 .c-ttl06 {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 834px) {
  .c-row .col3 .c-ttl06 {
    margin: 0 0 8px;
  }
}

.c-row .col3 .c-txt03 {
  max-width: 294px;
  max-height: 72px;
  margin: 0 auto 20px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .c-row .col3 .c-txt03 {
    margin: 0 auto 17px;
  }
}

@media screen and (max-width: 640px) {
  .c-row .col3 .c-txt03 {
    max-width: 100%;
    max-height: inherit;
  }
}

.c-row .col4 {
  width: calc(25% - 16px);
  margin-right: 21px;
  margin-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .c-row .col4 {
    width: calc(25% - 5px);
    margin-right: 6px;
  }
}

@media screen and (max-width: 640px) {
  .c-row .col4 {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 30px;
  }
  .c-row .col4:nth-child(even) {
    margin-right: 0;
  }
}

@media screen and (min-width: 641px) {
  .c-row .col4:nth-child(4n+4) {
    margin-right: 0;
  }
}

.c-row .col4 .img {
  margin: 0 0 9px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .c-row .col4 .img {
    margin: 0 0 10px;
  }
}

.c-row .col4 .img:before {
  content: "";
  display: block;
  padding-bottom: calc(158/234*100%);
}

@media screen and (max-width: 834px) {
  .c-row .col4 .img:before {
    padding-bottom: calc(110/155*100%);
  }
}

.c-row .col4 .img img {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.c-row .col4 .c-ttl05 {
  margin: 0 0 7px;
}

@media screen and (max-width: 834px) {
  .c-row .col4 .c-ttl05 {
    margin: 0 0 9px;
  }
}

.c-row .col4 .c-txt03 {
  margin: 0 0 45px;
  max-height: 74px;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .c-row .col4 .c-txt03 {
    margin: 0 0 9px;
    max-height: inherit;
    padding: 0 10px;
  }
}

@media screen and (max-width: 349px) {
  .c-row .col4 .c-txt03 {
    padding: 0;
  }
}

.c-row .col4 .btn {
  max-width: 221px;
}

@media screen and (max-width: 834px) {
  .c-row .col4 .btn {
    max-width: 100%;
    padding: 0 16px;
  }
}

.c-tbl {
  overflow-y: hidden;
  overflow-x: auto;
  line-height: 1.7;
  margin: 0 0 11px;
}

@media screen and (max-width: 640px) {
  .c-tbl {
    margin: 0 -15px 17px 0;
    font-size: 1rem;
    line-height: 1.4;
  }
}

.c-tbl__main {
  width: 100%;
  min-width: 696px;
}

@media screen and (max-width: 834px) {
  .c-tbl__main {
    min-width: 474px;
  }
}

.c-tbl__main--02 .c-tbl__head {
  padding: 17px 15px 19px;
}

@media screen and (max-width: 640px) {
  .c-tbl__main--02 .c-tbl__head {
    padding: 8px 4% 10px;
  }
}

@media only screen and (max-width: 524px) {
  .c-tbl__main--02 .c-tbl__head {
    padding: 8px 2.6% 10px;
  }
}

.c-tbl__head, .c-tbl__cnt {
  border: 1px solid #000;
}

.c-tbl__head {
  background: #000;
  border-left-color: #f2f2f2;
  border-right-color: #f2f2f2;
  padding: 17px 5px 19px;
}

@media screen and (max-width: 640px) {
  .c-tbl__head {
    padding: 8px 2.3% 10px;
  }
}

@media only screen and (max-width: 521px) {
  .c-tbl__head {
    padding: 8px 1.8% 10px;
  }
}

.c-tbl__head:first-child {
  border-left-color: #000;
}

.c-tbl__head:last-child {
  border-right-color: #000;
}

.c-tbl__cnt {
  background: #f2f2f2;
  padding: 15px 5px;
}

@media screen and (max-width: 834px) {
  .c-tbl__cnt {
    padding: 8px 2px;
  }
}

.sec-mv {
  margin-bottom: 48px;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62.55vw;
  max-height: calc(100vh - 133px);
}

@media screen and (max-width: 1024px) {
  .sec-mv {
    max-height: calc(100vh - 57px);
  }
}

@media screen and (max-width: 834px) {
  .sec-mv {
    max-height: calc(100vh - 121px);
    height: 109.55vw;
    margin-bottom: 49px;
  }
}

.mv-ttl {
  text-align: center;
  font-size: 6.419rem;
  font-weight: bold;
  color: white;
  text-shadow: 0 8px 11px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 1024px) {
  .mv-ttl {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 834px) {
  .mv-ttl {
    font-size: 3.2rem;
  }
}

.mv-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.mv-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.sec-bnr {
  margin: 0 0 73px;
}

@media screen and (max-width: 834px) {
  .sec-bnr {
    margin: 0 0 34px;
  }
}

.sec-bnr .inner {
  padding: 0 7px;
}

.bnr-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -3.5px;
}

@media screen and (max-width: 834px) {
  .bnr-list {
    margin: 0 -2.5px;
  }
}

.bnr-list__item {
  width: 50%;
  margin: 0 0 10px;
  padding: 0 3.5px;
}

@media screen and (max-width: 834px) {
  .bnr-list__item {
    padding: 0 2.5px;
    margin-bottom: 5px;
  }
}

.bnr-list__item:first-child {
  width: 100%;
}

.sec-news {
  margin: 0 0 106px;
}

@media screen and (max-width: 834px) {
  .sec-news {
    margin: 0 0 49px;
  }
}

.news-list {
  font-size: 1.8rem;
  max-width: 703px;
  margin: 0 auto 36px;
}

@media screen and (max-width: 1024px) {
  .news-list {
    font-size: 1.55rem;
  }
}

@media screen and (max-width: 834px) {
  .news-list {
    font-size: 1.3rem;
    margin: 0 auto 17px;
  }
}

.news-list__item {
  border-bottom: 2px solid #858484;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .news-list__item {
    border-width: 1px;
    margin-bottom: 15px;
  }
}

.news-list__item:last-child {
  margin-bottom: 0;
}

.news-list__link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 11px 10px;
}

@media screen and (max-width: 640px) {
  .news-list__link {
    display: block;
    padding: 0 7px 5px;
  }
  .news-list__link > * {
    display: block;
  }
}

.news-list__date {
  width: 97px;
}

@-moz-document url-prefix() {
  .news-list__date {
    width: 117px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .news-list__date {
    width: 117px;
  }
}

@media screen and (max-width: 834px) {
  .news-list__date {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 640px) {
  .news-list__date {
    width: 100%;
    margin: 0 0 1px;
  }
}

@media screen and (max-width: 834px) {
  .news-list__ttl {
    font-size: 1.4rem;
  }
}

.news-btn {
  max-width: 842px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .news-btn {
    padding: 0 1px;
  }
}

.sec-comp {
  margin: 0 0 130px;
}

@media screen and (max-width: 834px) {
  .sec-comp {
    margin: 0 0 50px;
  }
}

.comp-bnr {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 0 21px;
}

@media screen and (max-width: 834px) {
  .comp-bnr {
    margin: 0 0 20px;
  }
}

.comp-bnr img {
  display: block;
  width: 100%;
}

.comp-img {
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .comp-img {
    margin-bottom: 30px;
  }
}

.sec-heading4 {
  margin: 0 0 59px;
}

@media screen and (max-width: 834px) {
  .sec-heading4 {
    margin: 0 0 31px;
  }
}

.sec-heading4 .c-list02 {
  margin: 0 0 147px;
}

@media screen and (max-width: 834px) {
  .sec-heading4 .c-list02 {
    margin: 0 -4px 54px -3px;
  }
}

.w-923 {
  max-width: 923px;
  margin: 0 auto;
}

.sec-faq {
  margin: 0 0 80px;
}

@media screen and (max-width: 834px) {
  .sec-faq {
    margin: 0 0 63px;
  }
}

.sec-faq .inner {
  max-width: 994px;
}

.faq-list {
  max-width: 733px;
  margin: 0 auto 116px;
}

@media screen and (max-width: 834px) {
  .faq-list {
    margin-bottom: 53px;
  }
}

.faq-list__item {
  border-bottom: 2px solid #858484;
}

@media screen and (max-width: 834px) {
  .faq-list__item {
    border-width: 1px;
  }
}

.faq-list__item.show {
  border-bottom: none;
}

.faq-list__item.show .faq-list__q {
  padding-bottom: 29px;
}

@media screen and (max-width: 834px) {
  .faq-list__item.show .faq-list__q {
    padding-bottom: 16px;
  }
}

.faq-list__item.show .faq-list__q:after {
  transform: translateY(-50%) rotate(0);
}

.faq-list__item.show .faq-list__q .faq-list__ico {
  top: 23px;
  left: 14px;
}

@media screen and (max-width: 834px) {
  .faq-list__item.show .faq-list__q .faq-list__ico {
    top: 12px;
    left: 6px;
  }
}

.faq-list__item .c-txt03 {
  line-height: 1.2;
}

@media screen and (max-width: 834px) {
  .faq-list__item .c-txt03 {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.faq-list__ico {
  font-size: 3.6rem;
  line-height: 1;
  color: #3047a0;
  position: absolute;
}

@media screen and (max-width: 834px) {
  .faq-list__ico {
    font-size: 3.1885rem;
  }
}

.faq-list__q {
  position: relative;
  padding: 32px 50px 17px 50px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}

@media screen and (max-width: 834px) {
  .faq-list__q {
    padding: 19px 27px 15px 33px;
  }
}

.faq-list__q:after {
  position: absolute;
  content: '▲';
  font-size: 1.5rem;
  line-height: 1.2;
  top: 52%;
  right: 24px;
  transform: translateY(-50%) rotate(180deg);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 834px) {
  .faq-list__q:after {
    right: 4px;
  }
}

.faq-list__q .faq-list__ico {
  top: 20px;
  left: 11px;
}

@media screen and (max-width: 834px) {
  .faq-list__q .faq-list__ico {
    top: 11px;
    left: 6px;
  }
}

.faq-list__a {
  display: none;
  position: relative;
  background: #e5e5e5;
  padding: 29px 30px 14px 48px;
}

@media screen and (max-width: 834px) {
  .faq-list__a {
    padding: 16px 33px 9px 33px;
  }
}

@media screen and (min-width: 1025px) {
  .faq-list__a .c-btn02 {
    font-size: 1.6rem;
  }
}

.faq-list__a .c-txt03 {
  margin-bottom: 13px;
}

@media screen and (max-width: 834px) {
  .faq-list__a .c-txt03 {
    margin-bottom: 1px;
  }
}

.faq-list__a .c-txt03:last-child {
  margin-bottom: 0;
}

.faq-list__a .faq-list__ico {
  top: 18px;
  left: 13px;
}

@media screen and (max-width: 834px) {
  .faq-list__a .faq-list__ico {
    top: 10px;
    left: 6px;
  }
}

.faq-box {
  max-width: 696px;
  margin: 0 auto 100px;
}

@media screen and (max-width: 834px) {
  .faq-box {
    margin: 0 auto 35px;
  }
}

.faq-img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
  padding: 30px 0 42px;
}

@media screen and (max-width: 640px) {
  .faq-img {
    text-align: center;
    padding: 38px 0 17px;
  }
}

.faq-img > * {
  position: relative;
  padding: 91px 0 0;
}

@media screen and (max-width: 640px) {
  .faq-img > * {
    padding: 51px 0 0;
  }
  .faq-img > * img {
    display: block;
    width: 100%;
    max-height: 208px;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
}

.faq-img > *:before {
  font-family: "HGGothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 2rem;
  line-height: 1.2;
  position: absolute;
  background: #525252;
  color: white;
  padding: 22px 5px 17px 15px;
  letter-spacing: 11px;
  min-width: 160px;
  box-sizing: border-box;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .faq-img > *:before {
    font-size: 1.5rem;
    min-width: 114px;
    padding: 10px 5px 8px 17px;
    letter-spacing: 7.7px;
  }
}

.faq-img > *:first-child {
  width: 40.9%;
  margin: 0 0 0 3.3%;
}

@media screen and (max-width: 640px) {
  .faq-img > *:first-child {
    width: 91%;
    margin: 0 0 17px -1px;
  }
}

.faq-img > *:first-child:before {
  content: 'Before';
  top: 8px;
  left: 52.7%;
  transform: translateX(-50%);
}

@media screen and (max-width: 640px) {
  .faq-img > *:first-child:before {
    left: 57.7%;
  }
}

.faq-img > *:first-child:after {
  font-family: "HGGothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 4.8rem;
  position: absolute;
  content: '➡';
  line-height: 1;
  color: #525252;
  left: 105%;
  top: 55%;
  transform: translateY(-50%);
}

@media screen and (max-width: 640px) {
  .faq-img > *:first-child:after {
    font-size: 2.9rem;
    left: 102.8%;
    transform: rotate(90deg);
    top: auto;
    bottom: -3px;
  }
}

.faq-img > *:last-child {
  width: 46.8%;
  margin: 0 -2px 0 auto;
}

@media screen and (max-width: 640px) {
  .faq-img > *:last-child {
    width: 100%;
    margin: 0;
    padding: 45px 1px 0 2px;
  }
  .faq-img > *:last-child img {
    max-height: 223px;
  }
}

.faq-img > *:last-child:before {
  content: 'After';
  top: 1px;
  left: 49.6%;
  transform: translateX(-50%);
}

.rank-box {
  max-width: 733px;
  margin: 0 auto 162px;
}

@media screen and (max-width: 834px) {
  .rank-box {
    margin-bottom: 72px;
  }
}

.rank-box .item {
  border-bottom: 2px solid #858484;
  position: relative;
  padding: 34px 0 20px 63px;
}

@media screen and (max-width: 834px) {
  .rank-box .item {
    border-width: 1px;
    padding: 24px 0 20px 31px;
  }
}

.rank-box .item .number {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2.4rem;
  line-height: 1;
  position: absolute;
  top: 18px;
  left: 10px;
  width: 23px;
  height: 41px;
  background: url("../img/index/ico-number.jpg") no-repeat;
  background-size: contain;
  padding: 0 0 4px 2px;
}

@media screen and (max-width: 1024px) {
  .rank-box .item .number {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 834px) {
  .rank-box .item .number {
    font-size: 1.8rem;
    left: 5px;
    width: 17px;
    height: 30px;
    padding: 0 0 4px 0;
  }
}

.rank-box .item .c-txt03 {
  line-height: 1.2;
}

@media screen and (max-width: 834px) {
  .rank-box .item .c-txt03 {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.sec-work {
  margin: 0 0 104px;
}

@media screen and (max-width: 834px) {
  .sec-work {
    margin: 0 0 72px;
  }
}

.sec-work .c-row--04 {
  max-width: 1160px;
  margin: 0 auto -4px;
  padding: 0 15px;
}

@media screen and (max-width: 640px) {
  .sec-work .c-row--04 {
    display: block;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 auto 30px;
  }
}

.sec-work .c-row--04 .col3 {
  width: calc(33.33% - 11px);
  margin-right: 16px;
  padding-bottom: 17px;
  position: relative;
  margin-bottom: 39px;
}

@media screen and (max-width: 640px) {
  .sec-work .c-row--04 .col3 {
    display: inline-block;
    width: 255px;
    margin-right: 6px;
    padding-bottom: 11px;
    margin-bottom: 13px;
  }
}

.sec-work .c-row--04 .col3:before {
  position: absolute;
  content: '';
  width: 100vw;
  top: 38.5%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #e6e6e6;
}

@media screen and (min-width: 641px) {
  .sec-work .c-row--04 .col3:nth-child(3n+3) {
    margin-right: 0;
  }
}

.sec-work .c-row--04 .col3.showImg .img:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: -100%;
  width: 100%;
  background: #e6e6e6;
}

.sec-work .c-row--04 .col3.showImg .img img {
  opacity: 0;
}

.sec-work .c-row--04 .col3.active:nth-child(3n+2) .img:after {
  animation-delay: 0.6s;
}

.sec-work .c-row--04 .col3.active:nth-child(3n+2) .img img {
  animation-delay: 0.6s;
}

.sec-work .c-row--04 .col3.active:nth-child(3n+3) .img:after {
  animation-delay: 1.2s;
}

.sec-work .c-row--04 .col3.active:nth-child(3n+3) .img img {
  animation-delay: 1.2s;
}

.sec-work .c-row--04 .col3.active .img:after {
  animation: hideBg 0.6s forwards;
}

.sec-work .c-row--04 .col3.active .img img {
  animation: showImg 0.3s forwards;
}

.sec-work .c-row--04 .col3 .link {
  display: block;
  position: relative;
  z-index: 1;
}

.sec-work .c-row--04 .col3 .img {
  margin: 0 0 3px;
  overflow: hidden;
}

.sec-work .c-row--04 .col3 .img:before {
  padding-bottom: calc(274/366*100%);
}

@media screen and (max-width: 834px) {
  .sec-work .c-row--04 .col3 .img:before {
    padding-bottom: calc(191/255*100%);
  }
}

.sec-work .c-row--04 .col3 .time {
  font-size: 1.8rem;
  color: #0b0b0b;
}

@media screen and (max-width: 1024px) {
  .sec-work .c-row--04 .col3 .time {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 834px) {
  .sec-work .c-row--04 .col3 .time {
    font-size: 1.2rem;
    margin: 0 0 1px;
  }
}

.sec-work .c-row--04 .col3 .ttl {
  font-size: 1.8rem;
  line-height: 1.3;
  margin: 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  .sec-work .c-row--04 .col3 .ttl {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .sec-work .c-row--04 .col3 .ttl {
    font-size: 1.4rem;
  }
}

.sec-work .c-row--04 .col3 .des {
  font-size: 1.4rem;
  line-height: 1.36;
  color: #252628;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  height: 38.08px;
}

@media screen and (max-width: 1024px) {
  .sec-work .c-row--04 .col3 .des {
    font-size: 1.2rem;
    height: 32.64px;
  }
}

@media screen and (max-width: 834px) {
  .sec-work .c-row--04 .col3 .des {
    font-size: 1rem;
    height: 27.2px;
  }
}

@keyframes showImg {
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideBg {
  0% {
    left: -100%;
  }
  40% {
    left: 0;
  }
  60% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.sec-makr {
  margin: 0 0 54px;
}

@media screen and (max-width: 834px) {
  .sec-makr {
    margin: 0 0 39px;
  }
}

.makr-box {
  margin: 0 0 61px;
}

@media screen and (max-width: 834px) {
  .makr-box {
    margin: 0 0 26px;
  }
}

.makr-box:last-child {
  margin-bottom: 0;
}

.makr-ttl {
  font-size: 2.4rem;
  text-align: center;
  line-height: 1;
  margin: 0 0 28px;
}

@media screen and (max-width: 1024px) {
  .makr-ttl {
    font-size: 1.95rem;
  }
}

@media screen and (max-width: 834px) {
  .makr-ttl {
    font-size: 1.5rem;
    padding-bottom: 12px;
    position: relative;
    margin: 0 0 24px;
  }
  .makr-ttl:before {
    position: absolute;
    content: '';
    height: 2px;
    background: #3a3a3a;
    width: 142px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

.makr-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  max-width: 904px;
  margin: 0 auto;
}

.makr-list .item {
  width: 11.11%;
  margin-bottom: 51px;
}

@media screen and (max-width: 1024px) {
  .makr-list .item {
    width: 16.66%;
  }
}

@media screen and (max-width: 834px) {
  .makr-list .item {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 640px) {
  .makr-list .item {
    width: 25%;
  }
}

.makr-list .item .logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 15px;
}

@media screen and (max-width: 834px) {
  .makr-list .item .logo {
    margin: 0 0 6px;
  }
}

.makr-list .item .txt {
  font-size: 1.1rem;
}

.sec-area {
  margin: 0 0 86px;
}

@media screen and (max-width: 834px) {
  .sec-area {
    margin: 0 0 44px;
  }
}

.area-cnt {
  max-width: 968px;
  margin: 0 auto;
}

.area-ttl {
  font-size: 2.3rem;
  margin: 0 0 32px;
}

@media screen and (max-width: 1024px) {
  .area-ttl {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 834px) {
  .area-ttl {
    font-size: 1.1rem;
    margin: 0 0 13px;
  }
}

.area-box {
  font-size: 1.6rem;
  line-height: 1.875;
  margin: 0 0 42px;
}

@media screen and (max-width: 1024px) {
  .area-box {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 834px) {
  .area-box {
    font-size: 1.1rem;
    line-height: 1.75;
    margin: 0 0 21px;
  }
}

.area-box:last-child {
  margin-bottom: 0;
}

.area-box .ttl {
  font-size: 1.9rem;
  line-height: 1.4;
  margin: 0 0 1px;
}

@media screen and (max-width: 1024px) {
  .area-box .ttl {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 834px) {
  .area-box .ttl {
    font-size: 1.1rem;
    margin: 0 0 3px;
  }
}

.area-box span {
  margin-right: 12px;
}

@media screen and (max-width: 834px) {
  .area-box span {
    margin-right: 8px;
  }
}

.page-products {
  padding: 0 0 100px 0;
}

.page-products .post_list {
  padding: 20px 0;
}

.page-products .post_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-products .post_item:after {
  content: "";
  display: block;
  width: 32.5%;
  height: 0;
}

.page-products .post_content {
  width: 32.5%;
  position: relative;
  padding: 25px 0;
}

@media screen and (max-width: 1024px) {
  .page-products .post_content {
    width: 49%;
  }
}

@media screen and (max-width: 834px) {
  .page-products .post_content {
    width: 100%;
  }
}

.page-products .post_content:after {
  position: absolute;
  content: "";
  background-color: #ededed;
  transform: translateX(-50%);
  top: 40%;
  left: 50%;
  bottom: 0;
  width: 100vw;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .page-products .post_content:after {
    top: 20vw;
  }
}

@media screen and (max-width: 834px) {
  .page-products .post_content:after {
    top: 40%;
  }
}

.page-products .post_content .img {
  width: 100%;
  object-fit: cover;
}

.page-products .post_date {
  font-size: 1.8rem;
  padding-bottom: 5px;
}

.page-products .post_ttl {
  font-size: 1.8rem;
  line-height: 1.3;
  margin: 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-products .post_txt {
  font-size: 1.4rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  height: 39.2px;
}

.wp-pagenavi {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi {
    font-size: 1.1rem;
  }
}

.wp-pagenavi a, .wp-pagenavi span {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  background-color: #FFF;
  border: solid 1px #999;
  padding: 5px;
  margin: 0;
  font-weight: bold;
  min-width: 40px;
  min-height: 40px;
}

@media screen and (max-width: 834px) {
  .wp-pagenavi a, .wp-pagenavi span {
    min-width: 34px;
    min-height: 34px;
  }
}

.wp-pagenavi a:nth-child(1), .wp-pagenavi span:nth-child(1) {
  border-radius: 4px 0 0 4px;
}

.wp-pagenavi a:last-child, .wp-pagenavi span:last-child {
  border-radius: 0 4px 4px 0;
}

.wp-pagenavi .current {
  color: #fff;
  background-color: #000;
  border-color: #000;
  font-weight: bold;
}

.page-news {
  padding: 40px 0 40px 0;
}

.page-news .news_list {
  padding: 40px 20px;
}

.page-news .news_content {
  padding: 20px 0;
}

.page-news .news_content:last-child {
  padding-bottom: 100px;
}

.page-news .news_ttl {
  font-family: "HGMincho", YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2.6rem;
}

.page-news .news_date {
  font-family: "HGMincho", YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.page-news .news_txt {
  font-family: "HGMincho", YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.page-news .news_link {
  text-decoration: underline 1px #000;
  font-size: 1.8rem;
  font-family: "HGMincho", YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
}

.page-news .news_link p {
  text-align: right;
}

.page-contact {
  padding: 40px 0 40px 0;
}

.page-contact .contact_inner {
  max-width: 765px;
  margin: 0 auto;
}

.page-contact .contact_txtbox {
  border: 1px solid #000;
  margin: 40px auto;
  padding: 25px 25px 22px;
  max-width: 570px;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_txtbox {
    padding: 15px;
  }
}

.page-contact .contact_txtboxttl {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_txtboxttl {
    font-size: 1.6rem;
  }
}

.page-contact .contact_form {
  margin-bottom: 40px;
}

.page-contact .contact_form dl {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ededed;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_form dl {
    flex-wrap: wrap;
  }
}

.page-contact .contact_form dl dt {
  width: 30%;
  background: #f7f7f7;
  padding: 20px;
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_form dl dt {
    font-size: 1.5rem;
    width: 100%;
    padding: 10px;
  }
}

.page-contact .contact_form dl dt.txt-top {
  align-items: flex-start;
  align-content: flex-start;
}

.page-contact .contact_form dl dt span {
  color: red;
  display: block;
  width: 100%;
}

.page-contact .contact_form dl dd {
  width: 70%;
  padding: 10px 10px 10px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_form dl dd {
    width: 100%;
    padding: 10px;
  }
}

.page-contact .contact_form dl dd span {
  display: block;
  width: 100%;
}

.page-contact .contact_form .custom-radio {
  font-size: 2rem;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 5px 0 5px 30px;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_form .custom-radio {
    font-size: 1.5rem;
  }
}

.page-contact .contact_form .custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.page-contact .contact_form .custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.page-contact .contact_form .custom-radio .checkmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #939393;
  border-radius: 50%;
}

@media screen and (max-width: 834px) {
  .page-contact .contact_form .custom-radio .checkmark {
    top: 5px;
  }
}

.page-contact .contact_form .custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #939393;
}

.page-contact .contact_form .form-control {
  letter-spacing: 2px;
  color: #000;
  padding: 10px 15px;
  background: #fff;
  width: 100%;
  border: 1px solid #000;
  height: 32px;
  max-width: 442px;
  appearance: none;
  font-size: 1.4rem;
  margin: 10px 0;
}

.page-contact .contact_form .form-control .file {
  border: none;
}

.page-contact .contact_form textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  height: 180px;
}

.page-contact .contact_form .show-file {
  width: 100%;
  padding: 10px 0;
}

.page-contact .contact_form .show-file li {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.page-contact .contact_form .show-file li:last-child {
  margin-bottom: 0;
}

.page-contact .contact_form .show-file li span {
  display: inline-block;
  width: auto;
}

.page-contact .contact_form .show-file li .cover-txt {
  display: inline-block;
  width: auto;
  line-height: 1;
  background: #efefef;
  border: 1px solid #ddd;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.page-contact .contact_form .show-file li .fill-txt {
  display: block;
  font-size: 2rem;
  font-weight: bold;
}

.page-contact .contact_form .show-file li input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.page-contact .complete {
  margin: 75px 0 700px;
  padding: 0 105px;
}

.page-contact .complete_ttl {
  font-size: 3.6rem;
  margin-bottom: 25px;
}

.page-contact .complete_txt {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.mw_wp_form_confirm .input-show {
  display: none;
}

.mw_wp_form_complete .input-show {
  display: none;
}
