.page-products {
	padding: 0 0 100px 0;
	
	.post {
		&_list {
			padding: 20px 0;
		}
		
		&_item {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			
			&:after {
				content: "";
				display: block;
				width: 32.5%;
				height: 0;
			}
		}
		
		&_content {
			width: 32.5%;
			position: relative;
			padding: 25px 0;
			@include mq(ipd) {
				width: 49%;
			}
			@include mq() {
				width: 100%;
			}
			
			&:after {
				position: absolute;
				content: "";
				background-color: #ededed;
				transform: translateX(-50%);
				top: 40%;
				left: 50%;
				bottom: 0;
				width: 100vw;
				z-index: -1;
				@include mq(ipd) {
					top: 20vw;
				}
				@include mq() {
					top: 40%;
				}
			}
			
			.img {
				width: 100%;
				object-fit: cover;
			}
		}
		
		&_date {
			@include font-size(18);
			padding-bottom: 5px;
		}
		&_ttl {
			@include font-size(18);
			line-height: 1.3;
			margin: 0 0 8px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&_txt {
			@include font-size(14);
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			display: -webkit-box;
			height: 14px*1.4*2;
		}
	}
}

.wp-pagenavi /*ページャーの位置*/
{
	@include font-size(13);
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	@include mq {
		@include font-size(11);
	}
	a, span /*ボタンのスタイル*/
	{
		@include flex(center, center);
		color: #000;
		background-color: #FFF;
		border: solid 1px #999;
		padding: 5px;
		margin: 0;
		font-weight: bold;
		min-width: 40px;
		min-height: 40px;
		@include mq {
			min-width: 34px;
			min-height: 34px;
		}
		&:nth-child(1) {
			border-radius: 4px 0 0 4px;
		}
		
		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
	
	.current /*currentスタイル*/
	{
		color: #fff;
		background-color: #000;
		border-color: #000;
		font-weight: bold;
	}
}

// .nums-page{
//     display: flex;
//     justify-content: center;
//     .wp-pagenavi{
//         border: 1px solid #cfcfcf;
//     }
//     .current{
//         background-color: #000;
//     }
// }