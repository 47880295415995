.page-contact {
	padding: 40px 0 40px 0;
	.contact {
		&_inner {
			max-width: 765px;
			margin: 0 auto;
		}
		&_txtbox {
			border: 1px solid #000;
			margin: 40px auto;
			padding: 25px 25px 22px;
			max-width: 570px;
			@include mq {
				padding: 15px;
			}
			&ttl {
				@include font-size(24);
				text-align: center;
				margin-bottom: 10px;
				font-weight: bold;
				@include mq {
					@include font-size(16);
				}
			}
		}
		&_form {
			margin-bottom: 40px;
			dl {
				display: flex;
				flex-wrap: nowrap;
				border-bottom: 1px solid #ededed;
				@include mq {
					flex-wrap: wrap;
				}
				dt {
					width: 30%;
					background: #f7f7f7;
					padding: 20px;
					@include font-size(20);
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					align-content: center;
					@include mq {
						@include font-size(15);
						width: 100%;
						padding: 10px;
					}
					&.txt-top{
						align-items: flex-start;
						align-content: flex-start;
					}
					span {
						color: red;
						display: block;
						width: 100%;
					}
				}
				dd {
					width: 70%;
					padding: 10px 10px 10px 30px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					@include mq {
						width: 100%;
						padding: 10px;
					}
					span{
						display: block;
						width: 100%;
					}
				}
			}
			.custom-radio {
				@include font-size(20);
				color: #000;
				width: 100%;
				display: block;
				position: relative;
				cursor: pointer;
				user-select: none;
				padding: 5px 0 5px 30px;
				@include mq {
					@include font-size(15);
				}
				input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					&:checked ~ .checkmark:after {
						display: block;
					}
				}
				.checkmark {
					position: absolute;
					top: 8px;
					left: 0;
					height: 20px;
					width: 20px;
					border: 1px solid #939393;
					border-radius: 50%;
					@include mq {
						top: 5px;
					}
					&:after {
						content: "";
						position: absolute;
						display: none;
						transform: translate(-50%, -50%);
						top: 50%;
						left: 50%;
						width: 14px;
						height: 14px;
						border-radius: 50%;
						background: #939393;
					}
				}
			}
			.form-control {
				letter-spacing: 2px;
				color: #000;
				padding: 10px 15px;
				background: #fff;
				width: 100%;
				border: 1px solid #000;
				height: 32px;
				max-width: 442px;
				appearance: none;
				font-size: 1.4rem;
				margin: 10px 0;
				.file {
					border: none;
				}
			}
			textarea.form-control{
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				resize: none;
				height: 180px;
			}
			.show-file{
				width: 100%;
				padding: 10px 0;
				li{
					position: relative;
					margin-bottom: 10px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					&:last-child{
						margin-bottom: 0;
					}
					span{
						display: inline-block;
						width: auto;
					}
					.cover-txt{
						display: inline-block;
						width: auto;
						line-height: 1;
						background: #efefef;
						border: 1px solid #ddd;
						@include font-size(14);
						font-weight: 500;
						padding: 5px 10px;
						border-radius: 5px;
						cursor: pointer;
						margin-right: 10px;
					}
					.fill-txt{
						display: block;
						@include font-size(20);
						font-weight: bold;
					}
					input{
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						opacity: 0;
						cursor: pointer;
					}
				}
			}
		}
	}
	.complete{
		margin: 75px 0 700px;
		padding: 0 105px;
		&_ttl{
			@include font-size(36);
			margin-bottom: 25px;
		}
		&_txt{
			@include font-size(16); 
			margin-bottom: 20px
		}
	}
}



.mw_wp_form_confirm .input-show {
	display: none;
}

.mw_wp_form_complete .input-show {
	display: none;
}